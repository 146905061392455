.toolbar {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;

    @mixin tool-set {
        display: flex;
        align-items: center;
    }

    .left-tool-set {
        @include tool-set;
        justify-content: flex-start;

        .toolItem:nth-child(n+2) {
            margin-left: 10px;
        }
    }

    .right-tool-set {
        @include tool-set;
        justify-content: flex-end;
    }

    
}