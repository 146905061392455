@import 'styles/appvariables.scss';

.usernavmenu {
  font-family: $fonts;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

// =================================
// USERNAV-SWITCH
// =================================

.usernav-photobutton {
  > .usernav-photobutton-picture {
    border: 3px solid #fff;
    border-radius: 50%;
    overflow: hidden;

    > img {
      border-radius: 50%;
      margin: 0 auto;
      height: 25px;
      width: 25px;
      object-fit: cover;
      vertical-align: middle;
    }
  }
}

.usernavmenu {
  .usernavconsole {
    z-index: 12000;
    position: fixed;
    right: 0;
    top: 0;
    max-width: $navconsole;
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    > .usernavconsole-header {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      z-index: 100000;
      height: $usernavheaderheight;

      > .usernavcard-myinfo {
        background-color: #fff;
        flex: 1 0 auto;
        display: flex;
        padding: 10px;
        gap: 10px;

        & .usernavcard-picture {
          flex: 0 0 $usernavpic;
          width: $usernavpic;
          height: $usernavpic;
          overflow: hidden;

          > img {
            border-radius: 50%;
            margin: 0 auto;
            height: $usernavpic;
            width: $usernavpic;
            object-fit: cover;
          }
        }

        & .usernavcard-matter {
          flex: 1 1 auto;
          line-height: 1.4;

          > * {
            text-overflow: ellipsis;
            overflow: hidden;
          }

          > :first-child {
            font-size: 18px;
            font-weight: 700;
            color: rgba($color: #000, $alpha: 0.9);
          }

          > :nth-child(2) {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            color: rgba($color: #000, $alpha: 0.7);
          }

          // Panel Nav and Sign Out Links under username
          > .usernavheader-links {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(3, max-content);

            > .hyperlink {
              cursor: pointer;
            }

            > :nth-child(odd) {
              font-size: 14px;
              line-height: 1.5;
              color: var(--default-color-blue);

              &:hover {
                color: #e10000;
              }
            }
          }
        }
      }

      > .usernavconsole-close {
        position: relative;
        width: $mainheaderheight;
        height: $mainheaderheight;
        line-height: $mainheaderheight;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #fff;
        color: #000;

        font-size: 24px;
        text-align: center;
        padding: 0 0 0 3px;
      }
    }

    > .usernavbox {
      position: relative;
      z-index: 1000000;
      width: 100%;
      max-height: calc(100vh - #{$usernavheaderheight} - #{$usernavfooterheight});

      @keyframes animateopacity {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }

    > .usernavconsole-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      height: $usernavfooterheight;
      width: 100%;
      z-index: 1000000;

      display: flex;
      padding: 0;

      > * {
        flex: auto;
        font-size: 12px;
        line-height: $usernavfooterheight;
        text-transform: uppercase;
        background-color: #ccc;
        color: #333;
        padding: 0px 5px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: #555;
          // border: 1px solid #FFF5C8;
          color: #fff;
        }
      }

      > .usernavfooter-signout {
        font-weight: bold;
        color: #000;

        &:hover {
          background-color: #e10000;
          color: #fff;
        }
      }
    }
  }

  &.usernavmenuhide {
    display: none;
  }

  &.usernavmenushow {
    display: block;
  }
}

// ==================================================================
// USERNAVBOX
// ==================================================================

.usernavbox {
  // Outside
  flex: 1 1 auto;
  margin: 0;
  z-index: 1000;
  height: 100%;
  // Inside
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative; // needed to get menus to take relative top 0 or right 0

  // @media screen and (min-width:$laptop-breakpoint) { flex: 0 0 $usernavbox; width: $usernavbox; margin: 0 auto 0 0; }
}

// ==================================================================
// USERNAVBOX CONTAINERS
// ==================================================================

.usernavboxheader {
  display: flex;
  flex-wrap: nowrap;
  // border-bottom: 1px solid #555;
  background-color: #fff;
  margin-bottom: 8px;

  > .usernavboxmenuclick,
  > .usernavboxsearchclick {
    width: $subheaderheight;
    cursor: pointer;
    text-align: center;
  }

  > .usernavboxmenuclick.usernavboxmenushow,
  > .usernavboxsearchclick.usernavboxfiltersshow {
    background-color: rgb(210, 210, 210);
  }

  > .usernavboxsearch {
    flex: 1 0 auto;
    padding: 0 2px;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 0 10px;
  }

  > .usernavboxmenuclick,
  > .usernavboxsearch,
  > .usernavboxsearchclick {
    position: sticky;
    top: 0;
    height: $subheaderheight;
    font-size: 14px;
    line-height: $subheaderlineheight;
  }
}

.usernavboxframe {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - #{$usernavheaderheight} - #{$subheaderheight} - #{$usernavfooterheight});

  > .usernavboxmenu,
  > .usernavboxfilters,
  > .usernavbox-container {
    width: 100%;
    height: calc(100vh - #{$usernavheaderheight} - #{$subheaderheight} - #{$usernavfooterheight});
    overflow-x: hidden;
    overflow-y: auto;
  }

  > .usernavboxmenu,
  > .usernavboxfilters {
    position: absolute;
    top: $subheaderheight;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100000;
  }

  .usernavboxmenu.usernavboxmenushow {
    display: block;
    left: 0;
    align-self: flex-start;
    // box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.3);
  }

  .usernavboxfilters.usernavboxfiltersshow {
    display: block;
    right: 0;
    // box-shadow: -2px 0px 3px rgba(0, 0, 0, 0.3);
  }

  .usernavboxmenu.usernavboxmenuhide,
  .usernavboxfilters.usernavboxfiltershide {
    display: none;
  }

  > .usernavbox-container {
    background-color: white;
    position: relative;
    width: 100%;
  }

  & .usernavnugget-separator {
    background-color: #f2f3f4;
    height: 35px;
    line-height: $subheaderlineheight;
    color: #222;
    padding: 0 15px;
    border-bottom: 1px solid #ccc;
  }
}

// ==================================================================
// USERNAVBOX >>> USERNAVNUGGET
// ==================================================================

.usernav-quicklinks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  padding: 4px;

  > * {
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    color: #555;

    &:hover {
      background-color: #d7e8f9;
      // border: 1px solid #19324B;
    }
  }
}
