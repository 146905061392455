/*
 * CSS common to all application pages and popups.
 *
 * All styles assume mobile-first design. The default styles are therefore
 * applicable at all screen resolutions and are gradually modified for
 * increasing screen widths.
 */
 :root {
  --actual-width: calc(100vw - var(--left-margin) - var(--right-margin));
  --brand-color-facebook: #405D9A;
  --brand-color-instagram: radial-gradient(circle at 30% 107%, #FDF497 0%, #FDF497 5%, #FD5949 45%, #D6249F 60%, #285AEB 90%);
  --brand-color-linkedin: #0073B0;
  --brand-color-pinterest: #E60023;
  --brand-color-telegram: #35ABE7;
  --brand-color-twitter: #1DA1F2;
  --brand-color-vimeo: #1393CD;
  --brand-color-whatsapp: #49C958;
  --brand-color-youtube: #FF0000;
  --default-border-bottom: 1px solid var(--default-border-bottom-color);
  --default-border-bottom-color: var(--default-border-color-dark);
  --default-border-color-dark: #DDD;
  --default-border-color-light: #EEE;
  --default-border-left: 1px solid var(--default-border-left-color);
  --default-border-left-color: var(--default-border-color-light);
  --default-border-right: 1px solid var(--default-border-right-color);
  --default-border-right-color: var(--default-border-color-dark);
  --default-border-top: 1px solid var(--default-border-top-color);
  --default-border-top-color: var(--default-border-color-light);
  --default-color-blue: #1E64B4;
  --default-color-blue-alice: #F5F7F8;
  --default-color-blue-light: #E1F5FF;
  --default-color-blue-medium: #A6C7FF;
  --default-color-green: #2E8B57;
  --default-color-green-light: #EBF5E1;
  --default-color-green-medium: #92D050;
  --default-color-grey-dark: #333;
  --default-color-grey-light: #F2F2F2;
  --default-color-grey-medium: #AAA;
  --default-color-orange: #FF5A00;
  --default-color-red: #E10000;
  --default-color-red-light: #FAE1E6;
  --default-color-red-medium: #F0A5AF;
  --default-color-tan: #DDD9C3;
  --default-color-teal: #006464;
  --default-color-teal-light: #F0FAFA;
  --default-color-teal-medium: #32C8C8;
  --default-color-yellow: #FFAF00;
  --default-color-yellow-light: #FFFAE1;
  --default-color-yellow-medium: #FFF096;
  --default-shadow-color: var(--default-border-color-light);
  --left-margin: 2.5vw;
  --right-margin: 2.5vw;
  --scrollbar-thumb-background: #EEE;
  --scrollbar-thumb-foreground: darkgrey;
}

.status-amber-bg
, .status-submitted-bg 
, .state-submitted-bg {
  background-color: #FFC107 !important;
}
.status-green-bg 
, .status-completed-bg 
, .state-completed-bg {
  background-color: #4CAF50 !important;
}
.status-red-bg
, .status-failed-bg 
, .state-failed-bg {
  background-color: #FF0000 !important;
}

.status-amber
, .status-submitted
, .state-submitted {
  color: #FFC107 !important;
}
.status-green-bg 
, .status-completed 
, .state-completed {
  color: #4CAF50 !important;
}
.status-red-bg
, .status-failed 
, .state-failed {
  color: #FF0000 !important;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
  scrollbar-color: var(--scrollbar-thumb-foreground) var(--scrollbar-thumb-background);
  scrollbar-width: thin;
  word-wrap: break-word;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*::-webkit-scrollbar {
  background-color: var(--scrollbar-thumb-background);
  height: 0.5rem;
  width: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-foreground);
  border-radius: 0.25rem;
  outline: 1px solid slategrey;
}

*::selection {
  background: #FFD7D7;
  color: #000;
}

@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  src: url("/src/assets/f/NotoSerif-Regular.ttf");
}

@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: url("/src/assets/f/NotoSerif-Bold.ttf");
}

@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  src: url("/src/assets/f/NotoSerif-Italic.ttf");
}

@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 700;
  src: url("/src/assets/f/NotoSerif-BoldItalic.ttf");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  src: url("/src/assets/f/NotoSansDisplay-VariableFont_wdth,wght.ttf");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  src: url("/src/assets/f/NotoSansDisplay-Italic-VariableFont_wdth,wght.ttf");
}


@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url("/src/assets/f/LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  src: url("/src/assets/f/LibreBaskerville-Italic.ttf");
}

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url("/src/assets/f/LibreBaskerville-Bold.ttf");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: url("/src/assets/f/SourceSerifPro-Regular.ttf");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;
  src: url("/src/assets/f/SourceSerifPro-Italic.ttf");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  src: url("/src/assets/f/SourceSerifPro-Bold.ttf");
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 700;
  src: url("/src/assets/f/SourceSerifPro-BoldItalic.ttf");
}

body,
html {
  font-family: Roboto, Arial, Verdana, Helvetica, sans-serif;
  font-size: 14px;
  height: 100%;
  line-height: 1.4;
  width: 100%;
}

html {
  scroll-behavior: smooth;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/*
* Pages are divided into three areas, as shown below:
*
*  ------------------------------------------------------------------------
* |                         I n t r o d u c t i o n                        |
*  ------------------------------------------------------------------------
* |                                                  |                     |
* |                                                  |                     |
* |                                                  |                     |
* |                                                  |                     |
* |                                                  |                     |
* |         C o n t e n t                            | P r o m o t i o n s |
* |                                                  |                     |
* |                                                  |                     |
* |                                                  |                     |
* |                                                  |                     |
*  ------------------------------------------------------------------------
* |                           C o n c l u s i o n                          |
*  ------------------------------------------------------------------------
*
* This design is implemented using inline blocks. The design can be implemented
* using the CSS grid layout. However, migrated to CSS grid makes the body and
* its immediate descendant main tag unscrollable, which then impacts many other
* features.
*/
body:not(.full-width) > main {
  margin-left: var(--left-margin);
  margin-right: var(--right-margin);
}

body:not(.site) > main {
  padding-bottom: 100px;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a.gradient-link {
  border-bottom: 0 !important;
  color: initial !important;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  overflow: hidden;
  padding: 0.25em;
  position: relative;
  text-decoration: none;
  transition: color .3s ease-out;
  vertical-align: bottom;
  z-index: 0;
}

a.gradient-link:active,
a.gradient-link:focus,
a.gradient-link:hover {
  color: #FFF !important;
}

a.gradient-link::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateY(calc(100% - 0.25em));
  transition: transform .25s ease-out;
  width: 100%;
  z-index: -1;
}

a.gradient-link:active::before,
a.gradient-link:focus::before,
a.gradient-link:hover::before {
  transform: translateY(0);
  transition: transform .25s ease-out;
}

a.gradient-link.gradient-blue::before {
  background: var(--default-color-blue);
}

a.gradient-link.gradient-blue-green::before {
  background-image: linear-gradient(90deg, var(--default-color-blue) 0%, #92D050 100%);
}

a.gradient-link.gradient-blue-red::before {
  background-image: linear-gradient(90deg, var(--default-color-blue) 0%, var(--default-color-red) 100%);
}

a.gradient-link.gradient-blue-yellow::before {
  background-image: linear-gradient(90deg, var(--default-color-blue) 0%, var(--default-color-yellow) 100%);
}

a.gradient-link.gradient-green::before {
  background: #92D050;
}

a.gradient-link.gradient-green-blue::before {
  background-image: linear-gradient(90deg, #92D050 0%, var(--default-color-blue) 100%);
}

a.gradient-link.gradient-green-red::before {
  background-image: linear-gradient(90deg, #92D050 0%, var(--default-color-red) 100%);
}

a.gradient-link.gradient-green-yellow::before {
  background-image: linear-gradient(90deg, #92D050 0%, var(--default-color-yellow) 100%);
}

a.gradient-link.gradient-red::before {
  background: var(--default-color-red);
}

a.gradient-link.gradient-red-blue::before {
  background-image: linear-gradient(90deg, var(--default-color-red) 0%, var(--default-color-blue) 100%);
}

a.gradient-link.gradient-red-green::before {
  background-image: linear-gradient(90deg, var(--default-color-red) 0%, #92D050 100%);
}

a.gradient-link.gradient-red-yellow::before {
  background-image: linear-gradient(90deg, var(--default-color-red) 0%, var(--default-color-yellow) 100%);
}

a.gradient-link.gradient-yellow::before {
  background: var(--default-color-yellow);
}

a.gradient-link.gradient-yellow-blue::before {
  background-image: linear-gradient(90deg, var(--default-color-yellow) 0%, var(--default-color-blue) 100%);
}

a.gradient-link.gradient-yellow-green::before {
  background-image: linear-gradient(90deg, var(--default-color-yellow) 0%, #92D050 100%);
}

a.gradient-link.gradient-yellow-red::before {
  background-image: linear-gradient(90deg, var(--default-color-yellow) 0%, var(--default-color-red) 100%);
}

a.link,
input[type="button"].link,
input[type="submit"].link,
span.link {
  background: transparent;
  border: 1px solid transparent;
  color: var(--default-color-grey-dark);
  cursor: pointer;
  filter: none;
  font-weight: normal;
  outline: none;
  padding: 0.25em 0.5em;
  vertical-align: baseline;
}

a.link {
  display: inline;
}

a.link:active,
a.link:focus,
a.link:hover,
input[type="button"].link:active,
input[type="button"].link:focus,
input[type="button"].link:hover,
input[type="submit"].link:active,
input[type="submit"].link:focus,
input[type="submit"].link:hover,
span.link:hover {
  background-color: var(--default-color-grey-light);
  border: 1px solid #D3D3D3;
}

abbr,
acronym {
  border: 0;
}

.boarder-zero {
  border: 0px !important;
}

.bold-font {
font-weight: bold;
}

.light-green-bg {
  background-color: var(--default-color-green-medium) !important;
}

.light-red-bg {
  background-color: var(--default-color-red-medium) !important;
}


caption,
th {
  text-align: left;
}

code,
kbd,
samp,
tt {
  font-family: monospace;
}

code {
  background: #CCC;
  border-radius: 5px;
  display: inline-block;
  font-size: 90%;
  padding: 0.25em 0.5em;
}

button,
input[type="button"],
input[type="submit"] {
  padding: 0.25em 1em;
  vertical-align: top;
}

button.disabled,
input[type="button"].disabled,
input[type="submit"].disabled,
select.disabled,
textarea.disabled {
  color: #E3E3E3;
}

button.search-control,
button.search-control:active,
button.search-control:focus,
button.search-control:hover {
  background: none;
  border: none;
  cursor: pointer;
}

canvas {
  -ms-touch-action: double-tap-zoom;
}

.chat-button {
background-color : rgb(14, 114, 237);
height : 56px;
width : 56px;
color : white;
padding: 0;
border-radius: 50%;
cursor: pointer;
border: 1px solid rgba(0, 0, 0, .05);
transition: 200ms opacity ease;
position: fixed;
right: 24px;
bottom: 24px;
overflow: hidden;
display: flex;
gap: 8px;
align-items: center;
justify-content: center;
box-shadow: 0 4px 20px 4px rgb(0 0 0 / 12%);
}

.curp {
  cursor: pointer;
}

.display-block {
  display: block ! important;
}

.display-inline {
  display: inline !important;
}

div.color-splash {
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
}

div.grid {
  display: table;
  table-layout: fixed;
  width: 100%;
}

div.grid > div.row {
  display: table-row;
}

div.grid > div.row > div.cell {
  display: table-cell;
}

div.ui-datepicker {
  z-index: 10 !important;
}

fieldset,
img {
  border: 0;
}

form.inline {
  display: inline-block;
  vertical-align: top;
}

.form-control{
  border-radius: 8px !important;
  overflow: hidden !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: bold;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0.125em;
}

h1,
h2 {
  font-family: Roboto, Arial, Verdana, Helvetica, sans-serif;
}

h1 {
  font-size: 2em;
  margin-bottom: 0.56em;
  margin-top: 0.56em;
  padding-bottom: 0.25em;
}

h2 {
  font-size: 1.7em;
  margin-bottom: 0.6225em;
  margin-top: 0.6225em;
}

h3 {
  font-size: 1.4em;
  margin-bottom: 0.73125em;
  margin-top: 0.73125em;
}

h4 {
  font-size: 1.2em;
  margin-bottom: 1.33em;
  margin-top: 1.33em;
}

h5 {
  font-size: 1.1em;
  margin-bottom: 1.39em;
  margin-top: 1.39em;
}

h6 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 1.56em;
  margin-top: 1.56em;
}

i.progress,
img.progress {
  display: none;
  vertical-align: middle;
}

i.verified {
  color: #10A64A;
}

img {
  outline: 0 none;
}

/*
* Mozilla Firefox displays the alt text for images before they start to load.
* This happens for example when a page contains many images, or when images are
* heavy, requiring a lot of time to load. The user experience then gets
* compromised on Firefox due to the alt text appearing in place of the images.
* The non-standard feature below hides text for images that have not yet
* started loading, thereby preventing alt text from appearing.
*/
img:-moz-loading {
  visibility: hidden;
}

img.image-load-lazy {
  background-color: var(--default-color-grey-light);
}

img#image-logo {
  display: block;
}

img.progress {
  display: none;
  vertical-align: middle;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  transition: all 200ms ease-in-out;
}

input[disabled],
input:disabled {
  background: var(--default-color-grey-light);
  cursor: not-allowed;
}

input:focus,
select:focus,
textarea:focus {
  font-weight: bold;
}

input,
textarea {
  padding: 0.25em 0.5em;
}

input.date,
input.date[disabled],
input.date[readonly],
input.date-field,
input.end-date,
input.end-date[disabled],
input.end-date[readonly],
input.start-date,
input.start-date[disabled],
input.start-date[readonly] {
  background: var(--default-color-blue-alice);
  display: inline-block !important;
  max-width: calc(100% - 1.5em) !important;
}

input[type="checkbox"].switch,
input[type="checkbox"].tick {
  border: 0;
  display: none;
  height: 0;
  outline: 0;
  visibility: hidden;
  width: 0;
}

input[type="checkbox"].switch + label,
input[type="checkbox"].tick + label {
  cursor: pointer;
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

input[type="checkbox"].switch + label {
  background: #AAA;
  border-radius: 1em;
  display: inline-block;
  font-family: serif;
  height: 2em;
  width: 4em;
}

input[type="checkbox"].switch.switch-toggle + label {
  background: var(--default-color-blue);
}

input[type="checkbox"].switch + label::after {
  align-items: center;
  background: #FFF;
  border-radius: 100%;
  color: #AAA;
  content: "";
  display: flex;
  height: 1.6em;
  justify-content: center;
  position: absolute;
  top: 0.2em;
  transform: translateX(0.2em);
  transition: all 0.3s;
  width: 1.6em;
}

input[type="checkbox"].switch:checked + label {
  background: var(--default-color-blue);
}

input[type="checkbox"].switch:checked + label::after {
  color: var(--default-color-blue);
  transform: translateX(2.2em);
}

input[type="checkbox"][disabled].switch + label {
  background: #EEE;
}

input[type="checkbox"][disabled].switch + label::after {
  color: #EEE;
}

input[type="checkbox"][disabled].switch:checked + label {
  background: var(--default-color-blue-medium);
}

input[type="checkbox"][disabled].switch:checked + label::after {
  color: var(--default-color-blue-medium);
}

input[type="checkbox"].tick + label {
  border-style: solid;
  border-width: 0.25em;
  height: 1.5em;
  transition: all ease-out 200ms;
  width: 1.5em;
}

input[type="checkbox"].tick + label::after {
  border-style: solid;
  border-width: 0;
  border-bottom-width: 0.25em;
  border-left-width: 0.25em;
  content: "";
  display: block;
  height: 0.75em;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 10%;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all ease-out 200ms;
  width: 1.5em;
}

input[type="checkbox"].tick:checked + label::after {
  opacity: 1;
}

input[type="email"]::-ms-clear,
input[type="number"]::-ms-clear,
input[type="password"]::-ms-clear,
input[type="search"]::-ms-clear,
input[type="tel"]::-ms-clear,
input[type="text"]::-ms-clear {
  height: 0;
  width: 0;
}

input[type="email"].search-control,
input[type="number"].search-control,
input[type="password"].search-control,
input[type="search"].search-control,
input[type="tel"].search-control,
input[type="text"].search-control,
select.search-control,
textarea.search-control {
  background: var(--default-color-blue-alice);
  border: 1px solid #E1E1E1;
  border-radius: 0.25em;
  box-shadow: none;
  font-weight: normal;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

input[disabled].search-control,
input[readonly].search-control,
input.search-control:disabled,
select[disabled].search-control,
select.search-control:disabled,
textarea[disabled].search-control,
textarea.search-control:disabled {
  background: var(--default-color-blue-alice);
}

input[type="password"].credential,
input[type="text"].credential {
  max-width: 10em;
  min-width: 10em;
  width: 10em;
}

input[type="email"].mandatory,
input[type="number"].mandatory,
input[type="password"].mandatory,
input[type="search"].mandatory,
input[type="tel"].mandatory,
input[type="text"].mandatory,
select.mandatory,
textarea.mandatory {
  border-style: dashed;
  background-color: #EEF3F8;
  color: #000;
}

li.mandatory {
color : #000;
}

li.optional {
color : #999;
}

input[type="number"] {
  text-align: right;
}

input[type="text"].long-text {
  width: 100% !important;
}

input[type="text"].lower-case {
  text-transform: lowercase;
}

input[type="text"].numeric {
  max-width: 7.5em;
  min-width: 5em;
}

input[type="number"]:focus,
input[type="text"].numeric:focus {
  font-weight: normal;
}

input[type="text"].upper-case {
  text-transform: uppercase;
}

input.error,
select.error,
textarea.error {
  background-color: #F2DEDE !important;
  border-color: #912323 !important;
}

input.error:-ms-input-placeholder {
  color: #912323 !important;
}

input.error:-moz-placeholder {
  color: #912323 !important;
}

input.error::-moz-placeholder {
  color: #912323 !important;
}

input.error::-webkit-input-placeholder {
  color: #912323 !important;
}

input.problem,
select.problem,
textarea.problem {
  background-color: #FAFFBD !important;
  box-shadow: 0 0 5px 0 var(--default-color-red);
}

kbd {
  background: #CDE;
  border: 1px solid #CCC;
  border-radius: 5px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  font-size: 80%;
  padding: 0.25em 0.5em;
}

label {
font-weight : normal;
font-size : 11px;
color : #222;
}

li.no-list {
  list-style: none;
}

ol.simple-list > li,
ul.simple-list > li {
  list-style: none;
  margin: 0;
}

p {
  margin: 0.5em 0 1em 0;
}

q::before,
q::after {
  content: '';
}

select {
  max-width: 100%;
  padding: 0.75em 0.5em;
}

select.error:-ms-input-placeholder {
  color: #912323 !important;
}

select.error:-moz-placeholder {
  color: #912323 !important;
}

select.error::-moz-placeholder {
  color: #912323 !important;
}

select.error::-webkit-input-placeholder {
  color: #912323 !important;
}

select[multiple="multiple"],
select.multiselect {
  height: 15em;
  width: 100%;
}


select.selection {
  border-radius: 5px;
  margin-top: 0.25em;
}

span.link {
  display: block;
  padding: 0.5em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table tr.form-row > td,
table tr.form-row > th {
  padding-bottom: 1em;
  padding-top: 0.5em;
}

table tr.form-row > td {
  padding-left: 0.5em;
}

table tr.form-row > th {
  padding-left: 2em;
}

table.grid {
  border: 1px solid #DDD;
  margin: 0.5em 0;
}

table.grid > * > tr > * {
  background: #FFF;
  border-bottom: 1px solid #DDD;
  border-left: var(--default-border-left);
  padding: 0.5em 1em;
}

table.grid > * > tr > th {
  font-weight: bold;
}

table.grid > tbody > tr > * {
  position: relative;
}

table.grid > tbody > tr:nth-child(even) > * {
  background-color: var(--default-color-grey-light);
}

table.grid > tbody > tr:nth-child(even) > *:first-child {
  border-left-color: #EEE;
}

table.grid > tbody > tr:hover > *:not(.setting-status-bar) {
  background-color: rgba(245, 245, 218, 0.5) !important;
}

table.grid > tbody > tr.error > * {
  background-color: #F2DCDB;
  font-weight: bold;
}

table.grid > tbody > tr.error:nth-child(even) > * {
  background-color: #DA9694;
}

table.grid > tbody > tr.error:hover > * {
  background-color: rgba(255, 0, 0, 0.5) !important;
}

table.grid > tbody > tr.warn > * {
  background-color: #FEEFB3;
}

table.grid > tbody > tr.warn:nth-child(even) > * {
  background-color: #FFA000;
}

table.grid > tbody > tr.warn:hover > * {
  background-color: rgba(255, 102, 17, 0.5) !important;
}

table.grid > thead > tr > * {
  background-color: #f2f2f2;
  border-left: 1px solid #DDD;
  color: #555;
  font-size: 1.1em;
  font-weight: bold;
}

table.grid > thead > tr > *:first-child {
  border-left-color: #DDD;
}

table.grid.blue > tbody > tr:nth-child(even) > * {
  background-color: var(--default-color-blue-light);
}

table.grid.blue > tbody > tr:hover > * {
  background-color: var(--default-color-blue-medium) !important;
}

table.grid.blue > thead > tr > * {
  background-color: var(--default-color-blue);
}

table.grid.green > tbody > tr:nth-child(even) > * {
  background-color: var(--default-color-green-light);
}

table.grid.green > tbody > tr:hover > * {
  background-color: var(--default-color-green-medium) !important;
}

table.grid.green > thead > tr > * {
  background-color: var(--default-color-green);
}

table.grid.purple > tbody > tr:nth-child(even) > * {
  background-color: #B4ABFC;
}

table.grid.purple > tbody > tr:hover > * {
  background-color: rgba(153, 87, 250, 0.5) !important;
}

table.grid.purple > thead > tr > * {
  background-color: #5918D0;
}

table.grid.red > tbody > tr:nth-child(even) > * {
  background-color: var(--default-color-red-light);
}

table.grid.red > tbody > tr:hover > * {
  background-color: var(--default-color-red-medium) !important;
}

table.grid.red > thead > tr > * {
  background-color: var(--default-color-red);
}

table.grid.teal > tbody > tr:nth-child(even) > * {
  background-color: var(--default-color-teal-light);
}

table.grid.teal > tbody > tr:hover > * {
  background-color: var(--default-color-teal-medium) !important;
}

table.grid.teal > thead > tr > * {
  background-color: var(--default-color-teal);
}

table.grid.yellow > tbody > tr:nth-child(even) > * {
  background-color: var(--default-color-yellow-light);
}

table.grid.yellow > tbody > tr:hover > * {
  background-color: var(--default-color-yellow-medium) !important;
}

table.grid.yellow > thead > tr > * {
  background-color: var(--default-color-yellow);
}

table.table-responsive td,
table.table-responsive th {
  display: block;
  width: 100%;
}

td.bottom,
th.bottom {
  vertical-align: bottom !important;
}

td.center,
th.center {
  text-align: center !important;
}

td.left,
th.left {
  text-align: left !important;
}

td.middle,
th.middle {
  vertical-align: middle !important;
}

td.right,
th.right {
  text-align: right !important;
}

td.top,
th.top {
  vertical-align: top !important;
}

.transparent {
  background-color: transparent
}

.transparent-hover:hover {
  background-color: transparent !important;
}

textarea {
  resize: vertical;
  width: 100%;
}

textarea.error:-ms-input-placeholder {
  color: #912323 !important;
}

textarea.error:-moz-placeholder {
  color: #912323 !important;
}

textarea.error::-moz-placeholder {
  color: #912323 !important;
}

textarea.error::-webkit-input-placeholder {
  color: #912323 !important;
}

ul > li {
  list-style: square;
}

ul > li > ul > li {
  list-style: disc;
}

ul.choice {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.choice > li {
  list-style-type: none;
  margin: 0 0.5em 0 0;
  text-align: center;
}

ul.choice > li input,
ul.choice > li label {
  bottom: 0;
  display: block;
  left: 0;
  right: 0;
  top: 0;
}

ul.choice > li input[type="radio"] {
  display: none;
  opacity: 0;
  visibility: hidden;
}

ul.choice > li input[type="radio"]:checked + label {
  background: var(--default-color-grey-dark);
  color: #FFF;
}

ul.choice > li label {
  background: #EEE;
  border: 1px solid #CCC;
  cursor: pointer;
  font-size: 90%;
  height: 3em;
  line-height: 3em;
  min-width: 3em;
  padding: 0 0.5em;
  vertical-align: middle;
  z-index: 90;
}

ul.choice > li:hover label {
  background: #AAA;
}

ul.hint li {
  list-style: square;
}

ul.progress {
  background-color: transparent !important;
  height: auto;
  list-style-type: none;
  margin: 2em 0.5em;
  overflow: auto;
  padding: 0;
  width: 100%;
}

ul.progress > li {
  border-color: var(--default-color-green);
  border-style: none;
  border-width: 0.2em;
  font-size: 90%;
  font-weight: bold;
  list-style: none;
  margin: 0;
  margin-left: 1em;
}

ul.progress > li::before {
  background-color: var(--default-color-green);
  border: none;
  border-radius: 1em;
  content: "";
  float: left;
  height: 1em;
  line-height: 1em;
  opacity: 0.5;
  position: relative;
  width: 1em;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

ul.progress > li.current::before {
  animation: 2s ease-out 0s infinite normal both running radiate;
  opacity: 1.0;
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

ul.progress > li.interrupted {
  border-color: var(--default-color-red);
  font-weight: normal;
}

ul.progress > li.interrupted::before {
  background-color: var(--default-color-red);
}

ul.progress > li.pending {
  border-color: silver;
  font-weight: normal;
}

ul.progress > li.pending::before {
  background-color: silver;
}

ul.progress > li.stopped {
  border-color: var(--default-color-yellow);
  font-weight: normal;
}

ul.progress > li.stopped::before {
  background-color: var(--default-color-yellow);
}

ul.regular-bullet-list {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

#container {
  max-width: 1005px;
  min-width: 960px;
  width: 100%;
}

#connectionSetting .right-border-green {
  border-right: 50px solid var(--default-color-green-medium) !important
}

#connectionSetting .left-border-green {
  border-left: 50px solid var(--default-color-green-medium) !important
}

#connectionSetting .right-border-red {
  border-right: 50px solid var(--default-color-red-medium) !important;
}

#connectionSetting .left-border-red {
  border-left: 50px solid var(--default-color-red-medium) !important;
}

#connectionSetting .bg-light-green {
  background-color: var(--default-color-green-medium) !important
}

#connectionSetting .bg-light-red {
  background-color: var(--default-color-red-medium) !important
}

#introduction {
  margin-bottom: 2em;
  position: relative;
}

#loginMenu a {
  color: #777;
  font-size: 90%;
}

#loginMenu a:active,
#loginMenu a:focus,
#loginMenu a:hover {
  text-decoration: underline;
}

#loginMenu button {
  background: var(--default-color-blue);
  border: 1px solid #FFF;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  filter: none;
  font-family: Roboto, Arial, Verdana, Helvetica, sans-serif;
  font-weight: bold;
  outline-color: transparent;
  padding: 0.5em 1em;
}

#loginMenu button:active {
  border: 1px solid #FFF !important;
  filter: none;
  outline: 1px solid #FFF !important;
}

#loginMenu input[type="password"],
#loginMenu input[type="text"] {
  padding: 0.5em 1em;
  width: 100%;
}

#popup {
  display: none;
}

#popup ol li {
  list-style: decimal outside none;
}

#popup ul li {
  list-style: none;
}

#popup .popup-load-progress {
  left: 50%;
  margin-left: -64px;
  margin-top: -64px;
  position: absolute;
  top: 50%;
}

#popupContainer {
  background-color: #FFF;
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  height: 90% !important;
  text-align: left;
  width: 90% !important;
}


#popupContainer iframe {
  border: none;
  height: 100%;
  outline: none;
  width: 100%;
}

#popupOverlay {
  background-color: #777;
  z-index: 100;
}

#progress {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  filter: alpha(opacity=30);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}

#progress #progress-indicator {
  left: 47%;
  position: absolute;
  top: 47%;
}

#promotion {
  display: none;
  float: right;
  margin-left: 1.5%;
  position: relative;
  width: 260px;
}

#promotion + #content {
  overflow: hidden;
}

/*Modify Bootstrap for box headers and box borders
used in panels and edit pages -- start*/
#content .box {
  border: 1px solid #CCC;
  color: #555;
  margin: 12px 0;
  border-radius: 8px;
}

#content .box .action-button {
  padding: 4px 10px;
}

#content .box-header.with-border {
  border-bottom: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
}

.vibgyorset.page-section .box-header.with-border{
  cursor: pointer;
}

#content .box-header.with-border .box-title,
#content .box-header.with-border .box-title span {
  font-size: 13px;
  color: #777;
  text-transform: uppercase;
}

#content .box-header.with-border .btn-col {
  display: inline;
  float: left;
  padding-right: 50px;
  padding-top: 8px;
}

#content .box-header.with-border .action-title {
  padding-left: 0px;
  padding-top: 5px;
}

#content .box-header.with-border .action-btn-label {
  padding-left: 5px;
  padding-right: 5px;
}

/*Override the expand/collapse button +/- sign
font color in user dashboard box headers*/
#content .btn.btn-box-tool {
  color: #777
}

/*Modify Bootstrap for box headers and box borders
used in panels and edit pages -- end*/
/*
#promotion + #content .page-title.page-title-material {
  margin: 0.3em;
  margin-bottom: 1.5em;
}
*/
/*
#promotion + #content .tab-container >  :first-child { padding-left: 0.3em; }
*/
#promotion .notice {
  background: var(--default-color-grey-light);
  background: -moz-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -webkit-gradient(linear, left bottom, right bottom, color-stop(0%, var(--default-color-grey-light)), color-stop(100%, #FFFFFF));
  background: -webkit-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -o-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -ms-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='var(--default-color-grey-light)', GradientType=0);
  padding: 15px;
}

#promotion .notice ol > li,
#promotion .notice ul > li {
  margin-left: 35px;
}

#promotion .notice ol > li {
  list-style: decimal;
}

#promotion .notice ul > li {
  list-style: square;
}

#promotion .slideshow > ul {
  height: 240px;
  width: 240px;
}

#promotion .slideshow > ul > li img {
  max-height: 240px;
  max-width: 240px;
}

#promotion > #promotionTools {
  display: none;
  position: absolute;
  right: -4px;
  top: 0;
  z-index: 1000;
}

#promotion:hover > #promotionTools {
  display: block;
}

#promotionNoticeBottom,
#promotionNoticeTop {
  display: inline-block;
  vertical-align: top;
}

#socialLinks,
#socialLinks input[type="checkbox"] {
  display: none;
}

.accordion > .accordion-tab {
  clear: both;
  padding-bottom: 15px;
}

.accordion > .accordion-tab > .accordion-tab-title {
  position: relative;
}

.accordion > .accordion-tab > .accordion-tab-title > .accordion-tab-preview {
  cursor: pointer;
  font-size: small;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: right;
}

.accordion > .accordion-tab > .accordion-tab-title > h2 + .accordion-tab-preview {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 2;
}

.accordion.accordion-box > .accordion-tab > .accordion-tab-body {
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  display: block;
  padding: 1em;
}

.accordion.accordion-box > .accordion-tab > .accordion-tab-title {
  background: -moz-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #E3E3E3));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: -o-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: -ms-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #E3E3E3 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#FFFFFF, endColorstr=#E3E3E3)";
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  cursor: pointer;
  font-size: 1.2em;
  padding: 0.25em 0 0.25em 2.2em;
}

.accordion.accordion-box > .accordion-tab > .accordion-tab-title::before {
  align-items: center;
  background-repeat: no-repeat;
  border-right: 1px solid #EEE;
  bottom: 0;
  color: #777;
  content: "\268A";
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.8em;
}

.accordion.accordion-box > .accordion-tab > .accordion-tab-title.collapsed::before {
  content: "\271A";
}

.accordion.accordion-flat > .accordion-tab > .accordion-tab-title {
  color: #000;
  font-size: 1.8em;
  margin-top: 0.6em;
}

.accordion.accordion-flat > .accordion-tab > .accordion-tab-title > h2 + .accordion-tab-preview {
  padding-bottom: 0;
  padding-top: 1em;
  right: 0;
}

.accordion.accordion-flat > .accordion-tab > .accordion-tab-title > .accordion-tab-preview > span {
  font-size: x-small;
  margin-right: 2px;
  text-transform: uppercase;
}

.accordion-switch {
  cursor: pointer;
  padding-right: 10px;
}

.admin-section-title {
border-bottom: 1px solid #CCC;
color : #222;
cursor: pointer;
padding: 6px 12px;
width: 100%;
}

.admin-section-title + * {
margin-top : 20px;
}

.alert {
  display: inline-block;
  font-size: 120% !important;
  padding: 4px;
  vertical-align: top;
}

.alert.correct {
  color: green;
}

.alert.error {
  color: red;
}

.align-center {
  margin: 0 auto;
}

.align-left {
  float: left;
}

.align-right {
  float: right !important;
}

.always-visible {
  display: block !important;
  visibility: visible !important;
}

.background-highlight {
  background: -moz-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, #E3E3E3));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: -o-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: -ms-linear-gradient(top, #FFFFFF 0%, #E3E3E3 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #E3E3E3 100%);
}

.barcode-container {
  padding: 15px;
}

.barcode-container > .barcode-heading,
.barcode-container > .barcode-value {
  font-family: monospace;
  letter-spacing: 0.5em;
  margin: 1.5em 0;
  text-align: center;
}

.barcode-container > .barcode-heading {
  font-size: 1.25em;
  font-weight: bold;
}

.barcode-container > .barcode-image > img {
  display: block;
  margin: 0 auto;
  max-width: 88%;
}

.borderless-frame {
  border: 0;
}

.box-with-padding {
  padding: 1.5em
}

.break-words {
  word-spacing: 100vw;
}

.button {
  background-clip: padding-box;
  background-color: #ECECEC;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#F4F4F4), to(#ECECEC));
  background-image: -moz-linear-gradient(#F4F4F4, #ECECEC);
  background-image: -ms-linear-gradient(#F4F4F4, #ECECEC);
  background-image: -o-linear-gradient(#F4F4F4, #ECECEC);
  background-image: linear-gradient(#F4F4F4, #ECECEC);
  border: 1px solid #D4D4D4;
  border-radius: 0.2em;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0.5em 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 0 #FFF;
  transform: scale(1);
  transform-origin: 0 0;
  white-space: nowrap;
  -moz-background-clip: padding;
}

.button:active,
.button:focus,
.button:hover {
  background-color: #DDD;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #EEEEEE), color-stop(100%, #DDDDDD));
  background-image: -webkit-linear-gradient(top, #EEEEEE 0, #DDDDDD 100%);
  background-image: -moz-linear-gradient(top, #EEEEEE 0, #DDDDDD 100%);
  background-image: -ms-linear-gradient(top, #EEEEEE 0, #DDDDDD 100%);
  background-image: -o-linear-gradient(top, #EEEEEE 0, #DDDDDD 100%);
  background-image: linear-gradient(to bottom, #EEEEEE 0, #DDDDDD 100%);
  border-color: #CCC;
  text-decoration: none;
}

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button.big {
  font-size: 125%;
}

.button.danger {
  color: #900;
}

.button.danger:active,
.button.danger:focus,
.button.danger:hover {
  background-color: #DC5F59;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#DC5F59), to(#B33630));
  background-image: -moz-linear-gradient(#DC5F59, #B33630);
  background-image: -ms-linear-gradient(#DC5F59, #B33630);
  background-image: -o-linear-gradient(#DC5F59, #B33630);
  background-image: linear-gradient(#DC5F59, #B33630);
  border-bottom-color: #A0302A;
  border-color: #B53F3A;
  color: #FFF;
}

.button.danger:active {
  background-color: #B33630;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#B33630), to(#DC5F59));
  background-image: -moz-linear-gradient(#B33630, #DC5F59);
  background-image: -ms-linear-gradient(#B33630, #DC5F59);
  background-image: -o-linear-gradient(#B33630, #DC5F59);
  background-image: linear-gradient(#B33630, #DC5F59);
  border-bottom-color: #BF4843;
  border-color: #A0302A;
}

.button.disabled {
  opacity: 0.5;
}

.button.pill {
  border-radius: 50em;
}

.button.primary {
  background-color: #3C8DDE;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#3072B3), to(#599BDC));
  background-image: -moz-linear-gradient(#3072B3, #599BDC);
  background-image: -o-linear-gradient(#3072B3, #599BDC);
  background-image: linear-gradient(#3072B3, #599BDC);
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
}

.button.primary:active,
.button.primary:focus,
.button.primary:hover {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#599BDC), to(#3072B3));
  background-image: -moz-linear-gradient(#599BDC, #3072B3);
  background-image: -o-linear-gradient(#599BDC, #3072B3);
  background-image: linear-gradient(#599BDC, #3072B3);
  border-bottom-color: #2A65A0;
  border-color: #3072B3;
}

.button-group {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: 0 0;
}

.button + .button,
.button + .button-group,
.button-group + .button,
.button-group + .button-group {
  margin-left: 15px;
}

.button-group li {
  float: left;
  padding: 0;
  margin: 0;
}

.button-group .button {
  float: left;
  margin-left: -1px;
}

.button-group > .button:not(:first-child):not(:last-child),
.button-group li:not(:first-child):not(:last-child) .button {
  border-radius: 0;
}

.button-group > .button:first-child,
.button-group li:first-child .button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-left: 0;
}

.button-group > .button:last-child,
.button-group li:last-child > .button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.button-group.minor-group .button {
  background-color: #FFF;
  background-image: none;
  border: 1px solid #D4D4D4;
  text-shadow: none;
}

.button-group.minor-group .button:hover,
.button-group.minor-group .button:focus {
  background-color: #599BDC;
}

.button-group.minor-group .button:active {
  background-color: #3072B3;
}

.button-group.minor-group .button.icon::before {
  opacity: 0.8;
}

.button-container .button,
.button-container .button-group {
  vertical-align: top;
}

.carousel li {
  display: none;
  float: left;
  margin: 0;
}

.carousel-controls {
  text-align: center;
}

.carousel-controls > span {
  cursor: pointer;
}

.carousel-page {
  display: inline-block;
  height: 18px;
  width: 18px;
}

.clear {
  clear: both;
  display: block;
  height: 0;
  width: 0;
}

.collapsed-vertically {
  max-height: 0 !important;
}

.complete,
.confirmation,
.success {
  background-color: #009245 !important;
  color: #FFF;
  margin: 0.5em 0;
}

.contact {
  margin: 0 5px;
}

.copy {
  cursor: pointer;
}

.dead {
  color: #D83F3F;
}

.desktop-only,
.tablet-only {
  display: none !important;
}

.device-frame {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.device-frame > iframe {
  border: 0;
  left: 0;
  position: absolute;
}

.device-frame.desktop-frame > iframe {
  height: 71.2%;
  margin-left: 3.3%;
  top: 4%;
  width: 93.4%;
}

.device-frame.mobile-frame > iframe {
  height: 81.4%;
  margin-left: 2.5%;
  top: 10.5%;
  width: 95%;
}

.device-frame.tablet-frame > iframe {
  height: 83.4%;
  margin-left: 2.8%;
  top: 8.3%;
  width: 94.3%;
}

.draggable {
  cursor: move;
}


.ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.expandables {
  outline: none;
}

.expandables > .expandable {
  border-bottom: 1px solid #B7B7B7;
}

.expandables > .expandable > .expandable-heading {
  cursor: pointer;
  padding: 1.25em 2em 1.25em 0;
  position: relative;
}

.expandable-switch {
  cursor: pointer;
  text-align: right;
  vertical-align: top;
}

.fc-condensed {
  font-stretch: condensed;
}

.fc-expanded {
  font-stretch: expanded;
}

.fc-extra-condensed {
  font-stretch: extra-condensed;
}

.fc-extra-expanded {
  font-stretch: extra-expanded;
}

.fc-inherit {
  font-stretch: inherit;
}

.fc-normal {
  font-stretch: normal;
}

.fc-semi-condensed {
  font-stretch: semi-condensed;
}

.fc-semi-expanded {
  font-stretch: semi-expanded;
}

.fc-ultra-condensed {
  font-stretch: ultra-condensed;
}

.fc-ultra-expanded {
  font-stretch: ultra-expanded;
}

.fd-italic {
  font-style: italic;
}

.fd-inherit {
  font-style: inherit;
}

.fd-normal {
  font-style: normal;
}

.fd-oblique {
  font-style: oblique;
}

.ff-cursive {
  font-family: cursive;
}

.ff-fantasy {
  font-family: fantasy;
}

.ff-inherit {
  font-family: inherit;
}

.ff-monospace {
  font-family: monospace;
}

.ff-sans-serif {
  font-family: sans-serif;
}

.ff-serif {
  font-family: serif;
}

.filter ul > li {
  list-style: none;
  margin: 0;
  min-width: 10em;
}

.filter > .filter-group,
.filter > .filter-options {
  background-color: var(--default-color-grey-light);
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: 0;
  display: none;
  list-style: none;
  margin-left: -1px;
  position: absolute;
  z-index: 100;
}

.filter:hover > .filter-group {
  display: flex;
}

.filter:hover > .filter-group > * {
  border-left: 1px solid #CCC;
}

.filter:hover > .filter-options {
  display: block;
}

.filter-heading,
.filter-option {
  border-bottom: var(--default-border-bottom);
  display: block;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.5em 1em;
  text-transform: none;
}

.filter-heading {
  font-size: 1.1em;
}

.filter-option {
  cursor: pointer;
}

.filter-option.selected,
.filter-option:hover {
  background-color: var(--default-color-grey-dark);
  color: #FFF;
}

.filter-option:hover > a {
  color: #FFF;
}

.floating-select {
  background: transparent;
  border: 1px solid #FFF;
  min-width: auto;
  outline: 1px solid #FFF;
}

.form-badge {
  color: #FFF;
  display: inline-block;
  font-style: normal;
  font-variant: small-caps;
  margin-left: 1em;
  padding: 0.25em 1em;
}

.form-badge.private {
  background: #D83F3F;
}

.form-badge.public {
  background: #009245;
}

.form-error {
  background-color: #FFCDD2;
  color: #D83F3F;
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding: 2em;
}

.form-warning {
  background-color: var(--default-color-yellow-light);
  color: var(--default-color-orange);
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding: 2em;
}

.form-warning ol,
.form-warning ul {
  margin-top: 10px;
}

.form-error ol,
.form-error ul {
  margin-top: 10px;
}

.form-field > input[type="password"],
.form-field > input[type="text"],
.form-field > textarea {
  min-width: 25%;
}

.form-field > input[type="password"]:focus,
.form-field > input[type="text"]:focus,
.form-field > textarea:focus {
  border-color: var(--default-color-blue);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-field > input[type="password"].narrow,
.form-field > input[type="text"].narrow {
  max-width: 20%;
  min-width: 20%;
  width: 20%;
}

.form-field.stretch {
  text-align: right;
  width: 60px;
}

.form-field-label input,
.form-field-label select,
.form-field-label textarea {
  padding-right: 0;
}

.form-field-label {
  font-size: 1.1em;
  font-weight: bold;
}

.form-field-label.stretch {
  width: 100%;
}

.form-field-label.tooltip {
  border-bottom-color: var(--default-color-grey-dark);
}

.form-field-label label {
  margin-bottom: 0;
}

.form-group,
.form-row {
  padding-bottom: 1em;
  width: 100%;
}

.form-instructions {
  background-color: rgba(230, 240, 240, 20%);
  border: 1px solid #EEE !important;
  /*
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  margin-bottom: 1em;
  */
  border-radius: 5px;
  font-style: italic;
  padding: 2em;
  text-align: justify;
}

.form-instructions.form-error {
  font-style: normal;
}

.form-row img.progress,
.form-row img.hint,
.form-row img.tooltip,
.form-row input,
.form-row select,
.form-row textarea {
  vertical-align: top;
}

.form-row img {
  max-width: 100%;
}

.form-row ul.form-row-panel {
  width: 100%;
}

.form-row ul.form-row-panel > li {
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  display: block;
  list-style-type: none;
  margin: 1em 0;
  padding: 1em;
  border-radius: 5px;
}

.form-row ul.form-row-panel > li > .form-row-panel-heading {
  border-bottom: var(--default-border-bottom);
  font-size: 13px;
  font-variant: small-caps;
  font-weight: bold;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.form-row-panel > li > .form-row {
  border-spacing: 0;
}

.form-row-partition {
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}

.form-row-partition-heading {
  border-bottom: 1px solid #DDD;
  margin-bottom: 10px;
}

.form-section {
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  margin-bottom: 10px;
  overflow: hidden;
  padding-bottom: 10px;
}

.form-section > * {
  padding-left: 15px;
  padding-right: 15px;
}

.form-section > h2 {
  background: -moz-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, var(--default-color-grey-light)));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: -o-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: -ms-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  font-family: Roboto, Arial, Verdana, Helvetica, sans-serif;
  font-size: 18px;
  font-variant: small-caps;
  font-weight: normal;
  margin: 0;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#FFFFFF, endColorstr=var(--default-color-grey-light))";
}

.form-section-heading {
  background-color: var(--default-color-grey-light);
  font-size: 14px;
  font-variant: small-caps;
  height: 14px;
  margin: 5px 0;
  padding: 5px;
}

.form-submit > * {
  vertical-align: bottom;
}

.frame-full {
  width: 100%
}

.frame-landscape {
  height: 52.5vw
}

.frame-portrait {
  height: 190.5vw
}

.framed {
  border-bottom-color: var(--default-border-bottom-color);
  border-left-color: var(--default-border-left-color);
  border-right-color: var(--default-border-right-color);
  border-style: solid;
  border-top-color: var(--default-border-top-color);
  border-width: 0.25em;
  box-shadow: 0.25em 0.25em 0.25em 0 var(--default-shadow-color);
  padding: 0.25em;
}

.fs-inherit {
  font-size: inherit;
}

.fs-large {
  font-size: large;
}

.fs-larger {
  font-size: larger;
}

.fs-medium {
  font-size: medium;
}

.fs-small {
  font-size: small;
}

.fs-smaller {
  font-size: smaller;
}

.fs-x-large {
  font-size: x-large;
}

.fs-x-small {
  font-size: x-small;
}

.fs-xx-large {
  font-size: xx-large;
}

.fs-xx-small {
  font-size: xx-small;
}

.fv-all-petite-caps {
  font-variant: all-petite-caps;
}

.fv-all-small-caps {
  font-variant: all-small-caps;
}

.fv-inherit {
  font-variant: inherit;
}

.fv-normal {
  font-variant: normal;
}

.fv-petite-caps {
  font-variant: petite-caps;
}

.fv-small-caps {
  font-variant: small-caps;
}

.fv-titling-caps {
  font-variant: titling-caps;
}

.fv-unicase {
  font-variant: unicase;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.fw-inherit {
  font-weight: inherit;
}

.hidden {
  border: 0 !important;
  display: none !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  visibility: hidden !important;
  width: 0 !important;
}

.highlighted-text {
  color: var(--default-color-grey-dark);
}

.hint {
  font-style: italic;
  margin: 0.5em 0;
  vertical-align: bottom;
  color: #777;
}

.horizontal-rule {
  border-bottom: 1px solid var(--default-color-grey-light);
  border-top: 1px solid #CCC;
  height: 0;
  margin: 5px 0;
  padding: 0;
}

.indent {
  padding-left: 2em !important;
}

.indent-2x {
  padding-left: 4em !important;
}

.indent-3x {
  padding-left: 6em !important;
}

.indent-4x {
  padding-left: 8em !important;
}

.indent-5x {
  padding-left: 10em !important;
}

.indent-6x {
  padding-left: 12em !important;
}

.inline-link {
  color: var(--default-color-blue);
}
.inline-link:hover {
  cursor: pointer;
}

.justify-center {
  text-align: center;
}

.justify-right {
  text-align: right;
}

.keyword {
  border-bottom: 1px dotted;
  font-weight: bold;
}

.legend {
  display: inline-block;
  height: 12px;
  margin-right: 5px;
  width: 24px;
}

.light {
  color: #DDD;
}

.list-menu {
  white-space: nowrap;
}

.listing-table .row-content,
.listing-table .row-heading {
  font-size: 11px;
}

.live {
  color: #009245;
}

.logo-picture {
  background-color: #FFF;
  border: 1px solid #EEE;
  height: 75px !important;
  padding: 5px;
  margin: 2px;
  position: relative !important;
  width: 75px !important;
}

.lower-case {
  text-transform: lowercase;
}

.map iframe {
  height: 40em;
  padding: 2em;
  width: 100%;
}

.margin-bottom-zero {
  margin-bottom: 0px !important;
}

.margin-top-15 {
margin-top : 15px;
}

.margin-y-zero {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.margin-x-default {
margin-left: var(--left-margin) !important;
margin-right: var(--right-margin) !important;
}

.menu {
  position: relative;
}

.menu ul.list-menu,
.menu > ul {
  background-color: #FCFCFC;
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  cursor: default;
  display: none;
  list-style: none;
  margin-top: -1px;
  position: absolute;
  text-align: left;
  top: 1.2em;
  z-index: 20000;
}

.menu > ul.align-right,
.menu > * > ul.align-right {
  right: -1em;
}

.menu ul.list-menu > li,
.menu > ul > li {
  list-style: none;
  margin: 0;
  min-width: 10em;
  padding: 0 1em;
  position: relative;
}

.menu ul > li a,
.menu ul > li span {
  display: block;
  padding: 0.5em;
}

.menu:hover > ul,
.menu:hover > * > ul {
  display: block;
}

.nav {
  border: 1px solid var(--default-color-grey-light);
}

.nav a:not(.nav-link):active,
.nav a:not(.nav-link):focus,
.nav a:not(.nav-link):hover,
.nav span:hover {
  color: #00B0F0;
}

.nav-heading,
.nav-link {
  margin: 0;
  padding: 0 10px 0 15px;
}

.nav-heading:hover,
.nav-link:hover {
  border-left: 5px solid #00B0F0;
  padding-left: 10px;
}

.nav-heading {
  font-weight: bold;
  padding-bottom: 10px;
}

.nav-link,
.nav-link a,
.nav-link span:not(.unread-count) {
  font-size: 11px;
  color: #777;
}

.nav-link .banner {
  cursor: default;
  display: none;
  left: -1px;
  list-style: none;
  position: absolute;
  bottom: 106px;
  z-index: 20000;
}

.nav-link:hover .banner {
  display: block;
}

.negative-balance {
  color: var(--default-color-red);
}

.no-border {border : none;}

.off-canvas {
  left: -100vw;
  position: absolute;
  top: -100vh;
}

.operation-progress {
  clear: both;
  display: none;
  margin-top: 20px;
  padding: 5px 10px;
  text-align: center;
}

.padding-right-zero {
  padding-right: 0px;
}

.padding-left-zero {
  padding-left: 0px;
}

.padding-x-zero {
  padding-right: 0px;
  padding-left: 0px;
}

.padding-y-zero {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-zero {
  padding: 0px;
}

.row-bottom-margin {
margin-bottom: 15px;
}

.page-break {
  page-break-after: always;
}

.page-heading {
  color: #085F9C;
  font-size: 35px;
  font-weight: normal;
  text-align: left;
}

.page-subheading {
  color: #777;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: justify;
}

.page-tag {
  background-color: var(--default-color-grey-light);
  color: var(--default-color-grey-dark);
  float: left;
  line-height: 32px;
  width: 100%;
}

.page-tag-icon {
  vertical-align: middle;
}


/*
.page-title > h2:first-of-type {
  padding-left: 0.6em;
}*/
.page-title > h2.divider::before {
  content: "|";
  margin: 0 0.5em;
}

.page-title .motif {
  font-size: 1em;
  margin-right: 0.25em;
}

.page-title + .form-error,
.page-title + .form-instructions {
  margin-top: 1.5em;
}

.page-title > .page-action {
  border-top: var(--default-border-top);
  font-size: 1.35em;
  width: auto;
}

.page-title > .page-action > .page-action-button {
  background: linear-gradient(to bottom, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  border-left: 1px solid var(--default-color-grey-light);
  cursor: pointer;
  display: table-cell;
  height: 2em;
  text-align: center;
  transition: all 200ms ease;
  vertical-align: middle;
}

.page-title > .page-action > .page-action-button:hover {
  background: var(--default-color-grey-dark);
  color: #FFF;
  font-weight: bold;
}

.page-title > .page-action > .page-action-button > * {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 2em;
  vertical-align: middle;
}

.page-title > .page-action > .page-action-button > button {
  background: linear-gradient(to bottom, #EEECE1 0%, #DDDEC3 100%);
  border: 1px solid #FFF;
  cursor: pointer;
  font-size: inherit;
  height: 100%;
  outline: none;
  padding: 0.25em;
}

.page-title > .page-action > .page-action-button > button:active,
.page-title > .page-action > .page-action-button > button:focus,
.page-title > .page-action > .page-action-button > button:hover {
  background: #CCCC99;
  font-weight: bold;
}

.page-title > .page-action > .page-action-button > button:active {
  background: linear-gradient(to bottom, #DDDEC3 0%, #EEECE1 100%);
}

.page-title > .page-action > .page-action-button > button[disabled] {
  cursor: default;
  opacity: 0.4;
}

.page-title > .page-action > .page-action-button > .page-action-helper {
  border-left: 1px solid var(--default-color-grey-light);
  font-size: 0.5em;
  width: 1em;
}

.page-title > .page-action > .page-action-button > .page-action-helper > .list-menu {
  top: 4.1em;
  z-index: 2;
}

.page-title:not(.page-title-material) + #showcase {
  border-top: 0;
}

.page-title.page-title-material {
  vertical-align: middle;
  border-bottom: 2px solid #DDD;
  margin-left: calc(-1 * var(--left-margin));
  margin-right: calc(-1 * var(--right-margin));
  padding-left: var(--left-margin);
  padding-right: var(--right-margin);
  margin-bottom: 20px;
}

.page-title.page-title-material > h1 {
  font-weight: 500;
  padding: 0.6em;
  font-size: 18px;
  color: #555;
  vertical-align: middle;
}

.page-title.page-title-material .accordion-switch {
  padding: 0;
}

.page-title.page-title-material > .page-action {
  padding: 0.25em;
}

.page-title.page-title-material > .page-action > .page-action-button {
  background: none;
  border-left: 0;
  border-radius: 100%;
  position: relative;
  vertical-align: middle;
}

.page-title.page-title-material > .page-action > .page-action-button::before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.page-title.page-title-material > .page-action > .page-action-button:hover {
  background: none;
  color: inherit;
  font-weight: inherit;
}

.page-title.page-title-material > .page-action > .page-action-button:hover::before {
  opacity: 0.08;
}

.page-title.page-title-material > .page-action > .page-action-button > button {
  background: transparent;
  border: 0;
}

.page-title.page-title-material > .page-action > .page-action-button > button:active,
.page-title.page-title-material > .page-action > .page-action-button > button:focus,
.page-title.page-title-material > .page-action > .page-action-button > button:hover {
  background: inherit;
  font-weight: inherit;
}

.page-title.page-title-material > .page-action > .page-action-button > .page-action-helper {
  border-left: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

/*PANEL BUTTON DROPDOWN CUSTOM start*/
@media (min-width: 992px) {}

/*
.btn-menu > .btn-default.focus,
.btn-menu > .btn-default:focus,
.panelnav .btn.btn-default.active,
.panelnav .btn.btn-default:active,*/
.panelnav .btn.btn-default.hover,
.panelnav .btn.btn-default:hover {
  /*    .panelnav .open > .dropdown-toggle.btn-default {*/
  color: #FFF;
  background-color: #333;
  border-color: transparent;
}

.panelnav .btn.btn-default.active,
.panelnav .btn.btn-default:active,
.panelnav .btn-group.open .dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  outline: none;
  -webkit-box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  -moz-box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.panelnav .btn.btn-default,
.btn-menu > .btn-default {
  background-color: #FFF;
  color: #777;
  border-color: transparent;
  height: 50px;
  line-height: 1;
  padding: 6px 8px;
}

.btn-menu > .dropdown-menu {
  height: auto;
  max-height: calc(100vh - 100px);
  width: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 12px rgb(0 0 0 / 18%);
}

/*.btn-menu > .dropdown-menu > li > .dropdown-header {*/
.btn-menu .dropdown-header {
  padding: 6px 12px;
  color: #506478;
  font-weight: 500;
  cursor: context-menu;
}

/*
@media (max-width:360px) {
  .btn-menu > .dropdown-menu {
      height: auto;
      max-height: calc(100vh - 100px);
      width: clamp(200px, 80vw, 225px);
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      background-color: #EBF5FA;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
  }
}*/
/*BUTTON DROPDOWN CUSTOM end*/
/*PANELNAV DROPDOWN MENU BUTTON start*/
/* Define the "system" font family */
span.icon {
  display: block;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
  font-size: 16px;
}

i.icon {
  display: block;
  padding-top: 2px;
  cursor: pointer;
}

.nav:not(.navbar-nav) > li {
  position: relative;
  display: inline-block;
  min-width: 40px;
  margin-left: 0;
}

@media (max-width:360px) {
  .nav:not(.navbar-nav) > li {
      width: 16.66666667%;
  }
}

.menutextbelowicon {
  vertical-align: top;
  font-size: 12px;
  /*line-height: 0;*/
  cursor: pointer;
}

/**/
.navbar-nav > li > a {
  padding: 6px 12px;
}

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.nav:not(.bootstrap-nav) > li > a:active,
.nav:not(.bootstrap-nav) > li > a:focus,
.nav:not(.bootstrap-nav) > li > a:hover {
  color: #fff;
  background: #333;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: #333;
  border-color: transparent;
  color: #fff;
}

.nav a:not(.nav-link):active,
.nav a:not(.nav-link):focus,
.nav a:not(.nav-link):hover,
.nav span:hover {
  color: #fff;
}

/*PANEL BUTTON DROPDOWN MENU end*/
/*PAGE-ACTION-DROPDOWN HAMBURGER BUTTON start*/
.btn-menu > .page-action-dropdown {
  font-size: 12px;
  line-height: 100%;
  color: #FFF;
  border-radius: 50%;
  border-width: 0;
  background-color: #777;
  height: 26px;
  width: 26px;
  vertical-align: middle;
  align-items: center;
  padding: 8px;
  margin: 0 -0.4em 0 1em;
  cursor: pointer;
}

.btn-group.open .dropdown-toggle.page-action-dropdown,
.btn-menu > .page-action-dropdown:active,
.btn-menu > .page-action-dropdown:hover,
.btn-menu > .btn:hover {
  color: #FFF;
  background-color: #333;
  text-decoration: none;
}

/*PAGE-ACTION-DROPDOWN HAMBURGER BUTTON end*/
/*MODULEBAR start*/
.stretch {
  margin-left: calc(-2.5vw - 1.33333em);
  margin-right: calc(-2.5vw - 1.33333em);
}

.modulebar {
  height: auto;
  vertical-align: middle;
  margin-bottom: 0;
  margin-left: calc(-1 * var(--left-margin));
  margin-right: calc(-1 * var(--right-margin));
  padding-left: var(--left-margin);
  padding-right: var(--right-margin);
}

.modulebar > .navbar-default .navbar-brand {
  color: #FFF;
  font-size: 13.5px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 15px;
  line-height: 20px;
  height: auto;
}

.modulebar > .navbar {
  position: relative;
  min-height: 0;
  margin-bottom: 0;
}

/* Hamburger menu stripes color when for screen mobile */
.modulebar > .navbar-default .navbar-toggle .icon-bar {
  background-color: #CCC;
}

/* Hamburger mobile toggle height adjustment by reducing padding*/
.modulebar .navbar-toggle,
.modulebar .navbar-toggle:focus,
.modulebar .navbar-toggle:hover {
  padding: 9px 15px;
  border-color: transparent;
  background-color: transparent;
}

/* Active link is underlined */
.modulebar > .navbar-default .navbar-nav > .active > a {
  text-decoration: underline
}

.modulebar,
.modulebar > .navbar-default,
.modulebar > .navbar-default .navbar-nav > li > a,
.modulebar > .navbar-default .navbar-nav > .active > a,
.modulebar > .navbar-default .navbar-nav > .active > a:focus,
.modulebar > .navbar-default .navbar-nav > .open > a:focus,
.modulebar > .navbar-default .navbar-nav > li > a:focus,
.modulebar > .navbar-default .navbar-nav > .active > a:hover,
.modulebar > .navbar-default .navbar-nav > .open > a:visited {
  color: #CCC;
  background-color: #506478;
  font-size: 13.5px;
}

.modulebar > .navbar-default .navbar-nav > a,
.modulebar > .navbar-default .navbar-nav > a:focus,
.modulebar > .navbar-default .navbar-nav > a:hover,
.modulebar > .navbar-default .navbar-nav > .open > a,
.modulebar > .navbar-default .navbar-nav > .open > a:focus-within,
.modulebar > .navbar-default .navbar-nav > .open > a:hover,
.modulebar > .navbar-default .navbar-nav > li > a:hover {
  color: #FFF;
  background-color: #333;
}

.modulebar .nav,
.modulebar > .navbar,
.modulebar > .navbar-default,
.modulebar > .navbar-default .navbar-collapse,
.modulebar > .navbar-default .navbar-form {
  border: none;
  border-color: transparent;
}

.modulebar .navbar-nav{
  margin: 0;
}

.modulebar > ul,
.modulebar > span {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  margin-bottom: 0;
}

.modulebar > span {
  margin-right: 20px;
  color: #FFF;
  font-weight: 500;
  text-transform: capitalize;
}

.modulebar ul > li {
  margin: 0;
  list-style: none;
}

/*
.modulebar ul > li > a,
.modulebar ul > li > span {
  padding: 8px 8px 8px 8px;
  display: block;
  font-family: 'Roboto', Arial;
  font-size: 13px;
  text-align: center;
  color: #CCC;
  border-radius: 3px;
}*/

.modulebar ul > li.page-current > a {
  text-decoration-line: underline;
}

.modulebar ul > li:hover > a:hover {
  background-color: #333;
  color: #FFF;
}

.modulebar .dropdown-menu {
  padding: 6px 0;
}

.modulebar .btn {
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1;
}

.modulebar .btn-default {
  background-color: #fff;
}

/*MODULEBAR end*/
.popup-collapse {
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
}

.popup-container {
  display: none;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.popup-expand {
  background: var(--default-color-grey-light);
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  box-shadow: 4px 4px 4px #888;
  cursor: pointer;
  font-weight: bold;
  padding: 9px 14px;
  position: relative;
}

.popup-expand::before {
  content: "\0002B";
  display: block;
  font-size: 2em;
  position: absolute;
  right: 1em;
  top: 0.3em;
}

.popup-expand.expanded::before {
  content: "\02013";
  top: 0.2em;
}

.popup-expand > h3 {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0;
}

.popup-form {
  margin: 4em auto;
  max-width: 720px;
}

.popup-heading {
  margin: 0 0 10px 0;
}

.popup-info > #promotionTools {
  display: none;
}

.popup-info .notice {
  background: var(--default-color-grey-light);
  background: -moz-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -webkit-gradient(linear, left bottom, right bottom, color-stop(0%, var(--default-color-grey-light)), color-stop(100%, #FFFFFF));
  background: -webkit-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -o-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -ms-linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: linear-gradient(bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='var(--default-color-grey-light)', GradientType=0);
  padding: 15px;
}

.popup-info .notice ol > li,
.popup-info .notice ul > li {
  margin-left: 35px;
}

.popup-info .notice ol > li {
  list-style: decimal;
}

.popup-info .notice ul > li {
  list-style: square;
}

.popup-intro {
  margin-bottom: 15px;
  text-align: justify;
}

.popup-link {
  cursor: pointer;
}

.popup-switch {
  cursor: pointer;
  margin-bottom: 10px;
  text-align: right;
}

.popup-topic {
  margin-bottom: 2em;
}

.popup-topics {
  outline: none;
}

.popup-topic-content {
  padding: 20px;
  text-align: justify;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.pre {
  white-space: pre-wrap;
}

.preview {
  color: #DDD;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 99;
}

.preview > span {
  font-size: x-small;
  margin-right: 2px;
  text-transform: uppercase;
}

.profile {
  width: 100%;
}

.profiles {
  counter-reset: profile;
}

.profiles .profile-count::before {
  content: counter(profile)".";
  counter-increment: profile;
}

.profile .profile-details {
  font-size: 125%;
  margin-top: 1.2em;
  width: 100%;
}

.profile .profile-heading {
  font-size: 3em;
  font-variant: small-caps;
  font-weight: normal;
  padding: 0;
}

.profile-intro {
  padding: 0 1em;
  text-align: left;
}

.profile .profile-links {
  padding: 1em;
}

.profile .profile-links img {
  display: inline-block;
  vertical-align: middle;
}

.profile .profile-links li {
  list-style: none !important;
  margin: 0;
  padding: 0.25em 0;
}

.profile .profile-links a {
  display: inline-block;
  max-width: 2em;
  padding: 0 0.5em;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.profile-name,
.profile-title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.profile-name {
  font-weight: bold;
  white-space: nowrap;
}

.profile-photo {
  border: 1px solid #BDBAB0;
  border-radius: 8px;
  box-shadow: 5px 5px 5px #888;
  height: 192px;
  margin-bottom: 20px;
  padding: 5px;
  width: 192px;
}

.profile-photo > img {
  border-radius: 5px;
  display: inline-block;
  max-height: 180px;
  max-width: 180px;
}

.profile-picture-medium {
  display: inline-block;
  height: 180px;
  margin-left: 10px;
  overflow: hidden;
  width: 180px;
}

.profile-picture-raw {
  max-height: 420px;
  max-width: 420px;
  overflow: hidden;
}

.profile-section {
  background: #FFF;
  box-shadow: 0 0 1px 1px #CCC;
  font-size: 16px;
  margin: 0 2px 20px 2px;
  position: relative;
}

.profile-section .profile-section-content {
  height: 100%;
  width: 100%;
}

.profile-section .profile-section-description {
  color: var(--default-color-grey-dark);
  margin: 0;
  padding: 20px;
  word-wrap: break-word;
}

.profile-section .profile-section-title {
  background-color: var(--default-color-grey-dark);
  color: #FFF;
  font-weight: bold;
  max-width: 50%;
  overflow: hidden;
  padding: 10px 20px 10px 30px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-section-separator {
  background-color: #777;
  border-radius: 4em;
  color: #FFF;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}

.profile-section-tools {
  background: #FFF;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 2px 3px rgba(0, 0, 0, .2);
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  padding: 0.5em 1em;
}

.profile-section-tools > li {
  list-style: none;
  margin: 0;
}

.profile-section-tools > li:not(:first-child) {
  margin-top: 0.5em;
}

.profile .profile-subheading {
  color: #777;
  font-weight: normal;
  padding: 0;
}

.profile .profile-summary {
  margin-top: 1.5em;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.profile .profile-summary .member-tag {
  position: absolute;
  right: 5px;
  top: 5px;
}

.profile .profile-summary .profile-photo {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  padding: 5%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.profile .profile-summary .profile-photo img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.profile-title {
  height: 45px;
}

.promotion-box {
  background-color: #FFF;
  border-bottom: var(--default-border-bottom);
  border-left: var(--default-border-left);
  border-right: var(--default-border-right);
  border-top: var(--default-border-top);
  padding: 9px;
  width: 260px;
}

.promotion-box + .promotion-box {
  margin-top: 10px;
}

.promotion-box > h4 {
  border-bottom: thin dotted;
  margin: 5px 10px !important;
}

.row-content,
.row-heading {
  padding: 3px 10px;
}

.row-content {
  border-bottom: 1px solid var(--default-color-grey-light);
  border-left: 1px solid var(--default-color-grey-light);
  border-right: 1px solid var(--default-color-grey-light);
}

.row-heading {
  background-color: #777;
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
  border-right: 1px solid #FFF;
  color: #FFF;
}

.row-odd {
  background-color: var(--default-color-grey-light);
}

.row-wrapper {
  display: inline-block;
  text-align: left;
}

.screen {
  display: none;
  visibility: hidden;
}

.scroll-top {
  display: none;
}

.scrollable-table-container {
  border: 1px solid #DDD;
  border-radius: 4px;
  margin-bottom: 1em;
  min-height: auto;
  overflow-x: inherit;
  overflow-y: hidden;
  width: 100%;
  --scrollable-table-background: rgba(14, 30, 37, 0.02);
}

.scrollable-table-container > table {
  background: var(--scrollable-table-background);
}

.scrollable-table-container > table td,
.scrollable-table-container > table th {
  border-right: 0;
  padding: 0.5em;
  position: relative;
  white-space: normal;
}

.scrollable-table-container > table tr > td:first-child,
.scrollable-table-container > table tr > th:first-child {
  background: rgba(245, 247, 247, 1);
  background-clip: padding-box;
  left: 0;
  position: sticky;
  z-index: 9;
}

.scrollable-table-container > table tr > td:not(:last-child):after,
.scrollable-table-container > table tr > th:not(:last-child):after {
  background: #DDD;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
}

.scrollable-table-container > table > tbody > tr,
.scrollable-table-container > table > thead > tr {
  border-bottom: 1px solid #DDD;
}

.scrollable-table-container > table > tbody > tr:nth-child(odd) {
  background: var(--scrollable-table-background);
}

.scrollable-table-container > table > tbody > tr > td:nth-child(even) {
  background: var(--scrollable-table-background);
}

.scrollable-table-container > table > thead > tr > th:first-child {
  box-shadow: 2px 1px 5px 0px #DDD;
}

.section-heading {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 160px;
}

.selection-link {
  color: #00B0F0;
  cursor: pointer;
}

.selection-link:hover {
  color: #06F;
  font-weight: bold;
}

.shaded {
  background: var(--default-color-grey-light) !important;
}

.short-editor {
  height: 225px;
  width: 519px;
}

.showcase {
  position: relative;
}

.sidebar {
  overflow: hidden;
  position: fixed;
  z-index: 1000;
}

.sidebar-actions {
  background-color: var(--default-color-grey-light);
  background-repeat: no-repeat;
  border: 1px solid #BFBFBF;
}

.sidebar-actions > .sidebar-action {
  cursor: pointer;
}

.slideshow {
  position: relative;
}

.slideshow > ul {
  list-style: none;
  margin: 0;
  overflow: hidden;
}

.slideshow > ul > li {
  margin: 0;
}

.slideshow > img.backward,
.slideshow > img.forward {
  background-color: #EEE;
  cursor: pointer;
  display: none;
  padding: 3px;
  position: absolute;
  z-index: 100;
}

.slideshow:hover > img.backward,
.slideshow:hover > img.forward {
  display: block;
}

.slideshow > img.backward {
  left: 0;
}

.slideshow > img.forward {
  right: 0;
}

.slideshow > img.next,
.slideshow > img.previous {
  background: var(--default-color-grey-light);
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}

.slideshow > img.next {
  background: -moz-linear-gradient(top, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--default-color-grey-light)), color-stop(100%, #FFFFFF));
  background: -webkit-linear-gradient(top, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -o-linear-gradient(top, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: -ms-linear-gradient(top, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  background: linear-gradient(to bottom, var(--default-color-grey-light) 0%, #FFFFFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--default-color-grey-light)', endColorstr='#FFFFFF', GradientType=0) progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  top: 39%;
}

.slideshow > img.previous {
  background: -moz-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFFFF), color-stop(100%, var(--default-color-grey-light)));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: -o-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: -ms-linear-gradient(top, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, var(--default-color-grey-light) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='var(--default-color-grey-light)', GradientType=0) progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  top: 51%;
}

.small-textarea {
  width: 522px;
}

.stack {
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 1em 0;
}

.stack.stack-rounded {
  border-radius: 0.5em;
}

.summary-chart {
  display: block;
  height: 100%;
  margin-top: 1em;
  width: 100%;
}

.swap-source {
  background: #FFE5E5;
}

.swap-target {
  background: #E5FFE5;
}

.tab-container button {
  vertical-align: middle;
}

.tag {
  font-size: 11px;
}

.tc-blue {
  color: var(--default-color-blue);
}

.tc-blue-light {
  color: var(--default-color-blue-light);
}

.tc-blue-medium {
  color: var(--default-color-blue-medium);
}

.tc-green {
  color: var(--default-color-green);
}

.tc-green-light {
  color: var(--default-color-green-light);
}

.tc-green-medium {
  color: var(--default-color-green-medium);
}

.tc-grey-dark {
  color: var(--default-color-grey-dark);
}

.tc-grey-light {
  color: var(--default-color-grey-light);
}

.tc-orange {
  color: var(--default-color-orange);
}

.tc-red {
  color: var(--default-color-red);
}

.tc-red-light {
  color: var(--default-color-red-light);
}

.tc-red-medium {
  color: var(--default-color-red-medium);
}

.tc-tan {
  color: var(--default-color-tan);
}

.tc-teal {
  color: var(--default-color-teal);
}

.tc-teal-light {
  color: var(--default-color-teal-light);
}

.tc-teal-medium {
  color: var(--default-color-teal-medium);
}

.tc-yellow {
  color: var(--default-color-yellow);
}

.tc-yellow-light {
  color: var(--default-color-yellow-light);
}

.tc-yellow-medium {
  color: var(--default-color-yellow-medium);
}

.teaser.member-teaser,
.teaser.partner-teaser,
.teaser.people-teaser {
  border-left: var(--default-border-left);
  color: #FFF;
  display: inline-block;
  font-size: 11px;
  padding: 5px;
  text-align: center;
  vertical-align: top;
  width: 318px;
}

.teaser.member-teaser {
  border-left: none;
}

.teaser.partner-teaser {
  width: 290px;
}

.teaser.member-teaser:hover,
.teaser.partner-teaser:hover,
.teaser.people-teaser:hover {
  background-color: var(--default-color-grey-dark);
}

.teaser.member-teaser:hover a,
.teaser.partner-teaser:hover a,
.teaser.people-teaser:hover a {
  color: #FFF;
}

.teasers {
  border: 1px solid #EEE;
  margin-bottom: 25px;
  width: 958px;
}

.teasers > .teaser-content {
  width: 958px;
}

.teasers > .teaser-title {
  border-bottom: 1px solid #EEE;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 10px 15px;
  text-align: center;
  width: 928px;
}

.teasers .teaser > .teaser-title {
  color: #FFF;
  font-weight: bold;
}

.teasers a {
  color: #BFBFBF;
}

.teasers .teaser-title > img {
  margin-bottom: 3px;
  vertical-align: middle;
}

.terms {
  border: 1px solid var(--default-color-grey-light);
  font-size: 11px;
  height: 350px;
  overflow-y: scroll;
}

.text-danger {
  color: #a94442
}

.text-info {
  color: #31708f
}

.text-left {
  text-align: left !important
}

.text-light {
  color: #AAA
}

.text-muted {
  color: #777
}

.text-primary {
  color: #428bca
}

.text-success {
  color: #3c763d
}

.text-warning {
  color: #8a6d3b
}

.text-huge {
  font-size: 150%;
}

.text-large {
  font-size: 120%;
}

.text-medium {
  font-size: 90%;
}

.text-regular {
  font-size: 100%;
}

.text-small {
  font-size: 85%;
}

.text-tiny {
  font-size: 75%;
}

.tip:not(.fa),
.tooltip {
  cursor: help;
}

.tip:not(.fa):not(.tip-simple),
.tooltip {
  border-bottom: 1px dashed;
}

.tool {
  font-size: 13px !important;
  padding: 0 5px;
  visibility: hidden;
}

.tool > span {
  color: #376092;
}

.tool > span:hover {
  border-bottom: 1px dotted;
}

.topics {
  outline: none;
}

.topics > .topic > .topic-heading {
  border-bottom: 2px solid var(--default-border-color-light);
  cursor: pointer;
  margin-bottom: 10px;
  padding: 9px 25px 9px 0;
  position: relative;
}

.topic-switch {
  cursor: pointer;
  text-align: right;
  vertical-align: top;
}

.unselectable,
.unselectable * {
  user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.uppercase {
  text-transform: uppercase;
}

.verify {
  display: none;
}

.vertical-align-middle {
  top: 50%;
  transform: translateY(-50%);
}

.vertical-align-bottom {
  top: 100%;
  transform: translateY(-100%);
}

.vertical-scroll {
  height: auto;
  width: 100%;
}

.whatsapp-link {
  display: none;
}

/*
* Styles specific to devices on which mouse hover is available.
*/
@media (hover: hover) {
  #socialLinks > label {
      opacity: 0;
      visibility: hidden;
  }

  #socialLinks:hover > label {
      opacity: 1;
      visibility: visible;
  }
}

/*
* Styles specific to print media.
*/
@media print {
  body {
      position: static;
  }

  .sidebar {
      display: none;
  }

  .expandables > .expandable > .expandable-content,
  .topics > .topic > .topic-content {
      display: block !important;
  }

  .expandables > .expandable > .expandable-heading::before,
  .expandable-switch,
  .topics > .topic > .topic-heading::before,
  .topic-switch {
      display: none;
  }
}

/*
* Styles specific to projection and screen media.
*/
@media projection,
screen {
  html {
      overflow: hidden;
  }

  #socialLinks {
      display: block;
      font-size: 1.5em;
      z-index: 200;
  }

  #socialLinks input[type="checkbox"] ~ label {
      transform: rotate(180deg);
  }

  #socialLinks input[type="checkbox"]:checked ~ label {
      transform: rotate(0deg);
  }

  #socialLinks input[type="checkbox"] + ul {
      transform: translateX(-110%);
      visibility: hidden;
  }

  #socialLinks input[type="checkbox"]:checked + ul {
      transform: translateX(0);
      visibility: visible;
  }

  #socialLinks ul:empty + label {
      display: none;
  }

  #socialLinks > label,
  #socialLinks > ul {
      transition: all 0.3s ease-in-out;
  }

  #socialLinks > label,
  #socialLinks > ul > li > a {
      align-items: center;
      display: flex;
      justify-content: center;
  }

  #socialLinks > label {
      color: var(--default-color-grey-medium);
      cursor: pointer;
      font-size: 75%;
      font-weight: 400;
      width: 2.333333333em;
  }

  #socialLinks > ul {
      overflow: hidden;
  }

  #socialLinks > ul > li {
      margin: 0.25em 0 0.25em 0.1em;
  }

  #socialLinks > ul > li > a {
      height: 1.75em;
      width: 1.75em;
  }

  #socialLinks > ul > li > a {
      background: var(--default-color-grey-dark);
      border-radius: 50%;
      color: #FFF;
  }

  .blinker {
      animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  }

  .box-with-border {
      border-bottom: var(--default-border-bottom);
      border-left: var(--default-border-left);
      border-right: var(--default-border-right);
      border-top: var(--default-border-top);
  }

  .box-with-border + .box-with-border {
      margin-top: 15px;
  }

  .box-with-shadow {
      box-shadow: 0 8px 6px -8px #ccc;
      color: #777;
      border-radius: 0;
  }

  .expandables > .expandable > .expandable-content {
      padding: 0 1.25em;
  }

  .expandables > .expandable > .expandable-heading {
      padding: 1.25em 2.5em 1.25em 0;
  }

  .expandables > .expandable > .expandable-heading::before {
      content: "\203A";
      display: block;
      font-size: 1.5em;
      height: 1.5em;
      margin-top: -0.25em;
      position: absolute;
      right: 0.75em;
      transform: rotate(90deg);
      transition: all .7s ease-in-out;
  }

  .expandables > .expandable > .expandable-heading.expanded::before {
      transform: rotate(-90deg);
  }

  .expandables > .expandable > .expandable-heading:not(.expanded) ~ .expandable-content {
      display: none;
  }

  .popup-collapse {
      display: none;
  }

  .screen {
      display: block;
      visibility: visible;
  }

  .scroll-top {
      position: fixed;
      top: 45%;
      z-index: 10000;
  }

  .scroll-top-left {
      left: 0;
  }

  .scroll-top-right {
      right: 0;
  }

  .scroll {
      overflow: auto;
  }

  .tool {
      visibility: visible;
  }

  .topics > .topic > .topic-content {
      display: none;
      padding: 0 1.25em;
  }

  .topics > .topic > .topic-heading {
      border-bottom: 0;
      border-bottom: var(--default-border-bottom);
      border-left: var(--default-border-left);
      border-right: var(--default-border-right);
      border-top: var(--default-border-top);
      box-shadow: 4px 4px 4px #CCC;
      padding: 9px 25px 9px 14px;
      -moz-box-shadow: 4px 4px 4px #CCC;
      -webkit-box-shadow: 4px 4px 4px #CCC;
  }

  .topics > .topic > .topic-heading::before {
      content: "\203A";
      display: block;
      font-size: 1.25em;
      margin-top: -0.25em;
      position: absolute;
      right: 0.75em;
      transform: rotate(90deg);
      transition: all 0.7s ease-in-out;
  }

  .topics > .topic > .topic-heading.expanded::before {
      transform: rotate(-90deg);
  }

  .whatsapp-link {
      bottom: 24px;
      display: inline-block;
      font-size: 36px;
      position: fixed;
      right: 16px;
  }

  .whatsapp-link > i {
      background: transparent;
      border-radius: 6px;
      color: #49C958;
      padding: 4px 6px;
  }

  @keyframes blinker {
      from {
          opacity: 1;
      }

      to {
          opacity: 0;
      }
  }

  @keyframes bounceLeft {
      0% {
          opacity: 0;
          transform: translateX(100px)
      }

      33% {
          opacity: 1;
          transform: translateX(-50px)
      }

      66% {
          transform: translateX(25px)
      }

      to {
          transform: translateX(0)
      }
  }

  @keyframes radiate {
      0% {
          opacity: 0;
          transform: scale(0.5);
      }

      30% {
          opacity: 0.9;
      }

      100% {
          opacity: 0;
          transform: scale(2.0);
      }
  }
}

/*
* Styles specific to landscape orientation.
*/
@media (orientation:landscape) {
  ul.progress {
      display: table;
      table-layout: fixed;
      width: 99%;
  }

  ul.progress > li {
      border-top-style: solid;
      display: table-cell;
      line-height: 4em;
      text-align: center;
  }

  ul.progress > li::before {
      left: calc(50% - 0.2em);
      top: -0.6em;
  }

  .portrait-only {
      display: none;
      height: 0;
      visibility: hidden;
      width: 0;
  }
}

/*
* Styles specific to portrait orientation.
*/
@media (orientation:portrait) {
  ul.progress > li {
      border-left-style: solid;
      padding: 1em;
  }

  ul.progress > li::before {
      left: -1.6em;
      top: -0.4em;
  }

  .landscape-only {
      display: none;
      height: 0;
      visibility: hidden;
      width: 0;
  }
}

/*
* Styles specific to screens at or above tablet resolution.
*/
@media (min-width:720px) {

  table.table-responsive td,
  table.table-responsive th {
      display: table-cell;
      width: auto;
  }

  .form-field,
  .form-field-label {
      display: table-cell;
      vertical-align: middle;
  }

  .form-field {
      padding-left: 1em;
  }

  .form-group,
  .form-row {
      clear: both;
      display: table;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      table-layout: fixed;
  }

  .form-group > .form-field-label {
      width: 15%;
  }

  .form-row > .form-field-label {
      width: 25%;
  }

  .form-row > .form-field-label:first-child {
      text-align: right;
  }

  .form-row ul.form-row-panel {
      border-collapse: separate;
      border-spacing: 1em;
      display: table;
      table-layout: fixed;
  }

  .form-row ul.form-row-panel > li {
      display: table-cell;
      vertical-align: top;
  }

  .form-row-partition {
      display: table-cell;
  }

  .mobile-only {
      display: none;
      height: 0;
      visibility: hidden;
      width: 0;
  }

  /*
  .page-title > h1,
  .page-title > h2 {
      display: inline-block;
  }*/
  .page-title > h2 {
      font-size: 14px;
      padding: 1em 0 0.95em 0;
      vertical-align: top;
  }

  .page-title.page-title-material > h2 {
      /*      padding: 1.3em 0 0.25em 0;*/
      vertical-align: middle;
  }

  .page-title.page-title-material > .page-action {
      /*        padding: 0.33em;*/
      vertical-align: middle;
  }

  .profile {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }

  .profile .profile-details {
      flex: 1;
  }

  .profile .profile-heading,
  .profile .profile-subheading {
      width: 100%;
  }

  .profile .profile-summary {
      margin-right: 1.5em;
      padding-top: 20vw;
      width: 20vw;
  }

  .profile-section-tools {
      align-items: center;
      flex-direction: row;
  }

  .profile-section-tools > li:not(:first-child) {
      margin-left: 0.5em;
      margin-top: 0;
  }

  .tablet-only {
      display: inherit !important;
  }
}

/*
* Styles specific to screens at desktop resolution.
*/
@media (min-width:1024px) {
  :root {
      --left-margin: 5vw;
      --right-margin: 5vw;
  }

  body,
  html {
      font-size: 12px;
  }

  h1 {
      font-size: 2.4em;
  }

  h2 {
      font-size: 2em;
  }

  #promotion {
      display: inherit !important;
  }

  #socialLinks {
      font-size: 2em;
  }

  #socialLinks > ul > li > a.branded {
      background: none;
      border-radius: 0;
      color: inherit;
  }

  #socialLinks > ul > li > a.email.branded > i {
      color: var(--default-color-yellow);
  }

  #socialLinks > ul > li > a.facebook.branded > i {
      color: var(--brand-color-facebook);
  }

  #socialLinks > ul > li > a.instagram.branded > i {
      background: var(--brand-color-instagram);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

  #socialLinks > ul > li > a.pinterest.branded > i {
      color: var(--brand-color-pinterest);
  }

  #socialLinks > ul > li > a.linkedin.branded > i {
      color: var(--brand-color-linkedin);
  }

  #socialLinks > ul > li > a.telegram.branded > i {
      color: var(--brand-color-telegram);
  }

  #socialLinks > ul > li > a.twitter.branded > i {
      color: var(--brand-color-twitter);
  }

  #socialLinks > ul > li > a.vimeo.branded > i {
      color: var(--brand-color-vimeo);
  }

  #socialLinks > ul > li > a.whatsapp.branded > i {
      color: var(--brand-color-whatsapp);
  }

  #socialLinks > ul > li > a.youtube.branded > i {
      color: var(--brand-color-youtube);
  }

  .desktop-only {
      display: inherit !important;
  }

  .page-title > h1,
  .page-title > h2 {
      overflow: hidden;
      white-space: nowrap;
  }

  .page-title > h1 {
      max-width: 60%;
  }

  /*
  .page-title.page-title-material > h2 {
      padding: 1.7em 0 0.25em 0;
  }
*/
  .page-title > .page-action {
      border-top: 0;
      float: right;
      font-size: 16px;
  }

  .popup-expand::before {
      top: 0.5em;
  }

  .popup-expand.expanded::before {
      top: 0.4em;
  }

  .scrollable-table-container > table tr > td:first-child,
  .scrollable-table-container > table tr > th:first-child {
      white-space: nowrap;
  }

  .tablet-only {
      display: none;
      height: 0;
      visibility: hidden;
      width: 0;
  }
}

/*
* Styles specific to extra-wide screens.
*/
@media (min-width:1600px) {

  body,
  html {
      font-size: 13px;
  }
}
