// ==================================================================
// HOT-COMPONENT :: DROPDOWN MENU
// ==================================================================

.app-dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: block;
    float: left;

    height: auto;
    max-height: calc(100vh - 100px);
    width: 300px;
    min-width: 160px;

    overflow-x: hidden;
    overflow-y: auto;

    margin: 2px 0 0;
    padding: 5px 0;

    font-size: 13px;
    line-height: 1;
    text-align: left;
    list-style: none;

    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0 0 12px #DDD;
    border-radius: 4px;

    &.appdropdownhide {
        display: none;
    }

    &.appdropdownshow {
        display: block;
    }

    & a,
    & button,
    >div>a,
    >div>button {
        background-color: #fff;
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        text-align: left;
        clear: both;
        cursor:  pointer;
        outline: 0;
        font-weight: 400;

        &.disabled,
        &.disabled>span {
            color: #DDD;

            &:hover,
            &:hover>span {
                color: #AAA;
                background-color: #f5f5f5;
            }
        }
    }

    & a,
    & button,
    >div>a,
    >div>button,
    >div>button>.fa,
    >div>button>.fas,
    >div>button>.far,
    & .fa,
    & .fas,
    & .far {
        color: #777;
        white-space: normal;
        padding: 4px 10px;
    }

    & a:hover,
    & button:hover,
    >div>a:hover,
    >div>button:hover {
        background-color: #e5e5e5;
        color: #333;
        text-decoration: none;

        >* {
            color: #333;
        }
    }

    & a:focus,
    & button:focus,
    >div>a:focus,
    >div>button:focus,
    & div:focus {
        background-color: #f2f2f2;
        color: #262626;
        text-decoration: none;

        >* {
            color: #262626;
        }
    }

    & li {
        list-style: none;
        font-size: 13px;
        white-space: normal;
    }

    & .divider {
        background-color: #eee;
        height: 1px;
        margin: 7px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }

    & .appdropdown-groupname {
        color: #369;
        font-weight: 600;
        font-size: 13px;
        white-space: normal;
        padding: 8px 20px;
        text-align: left;
    }
}

.hangright {
    right: 0;
    left: auto;
}

.hangleft {
    right: auto;
    left: 0;
}

// ==================================================================
// TARGET CLOSEST NEXT
// ==================================================================

.target-closest-next~* {
    transition: all .7s ease-in-out;
    animation: animateopacity 0.4s;

    @keyframes animateopacity {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }

    &.hothide {
        display: none;
    }
}

// ==================================================================
// HOT-COMPONENTS :: END
// ==================================================================