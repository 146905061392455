/*
    rootnugget = nugget-main + nugget-side (chevron / sidearrow)
    nugget-main = nugget-card + nugget-toolbar + nugget-hierarchy
    nugget-card = nugget-picture + nugget-matter

    ---------------------------------------------------------------
    CARD                                                |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    |           |                                       |         |
    | picture   |                                       |  after  |
    | icon      |                                       |  icon   |
    | initials  |                                       |         |
    |           |                                       |         |
    -----------------------------------------------------         |
    HIERARCHY                                           |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    |           |                                       |         |
    | Level#    |  arrows + over10                      |         |
    | L7        |  treelevel-#                          |         |
    |           |                                       |         |
    -----------------------------------------------------         |
    TOOLBAR                                             |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    | menu      |  ribbon                               |         |
    |           |                                       |         |
    -----------------------------------------------------         |
    ABOUT                                               |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    |           |  metainfo                             |         |
    |           |                                       |         |
    -----------------------------------------------------         |
    PRICE                                               |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    |           |                                       |         |
    | orderqty  |  unitprice                            |         |
    |           |                                       |         |
    -----------------------------------------------------         |
    DISCOUNT                                            |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    |           |                                       |         |
    |           |                                       |         |
    -----------------------------------------------------         |
    DOCUMENT                                            |         |
    -c1         |  -c2                                  |         |
    -----------------------------------------------------         |
    |           |                                       |         |
    | status    |  metainfo                             |         |
    |           |                                       |         |
    ---------------------------------------------------------------


    ===============================================================
    ELEMENTS
    ===============================================================
    |           |                                       |         |
    | picture   |  pretitle                             |         |
    | icon      |  title                                |         |
    | initials  |  subtitle                             |         |
    |           |

    3cc
    objectcode / PIN
    brand
    account
    panel

    caption
    metainfo
    date
    status

    menu (toolbar-c1)
    ribbon (toolbar-c2)

    treelevel-number
    treelevel-marker
    ---treelevel-marker-arrows
    ------treelevel-marker1
    ------treelevel-marker2
    :::treelevel-marker:after (over10)

    orderqty
    unitprice
    basepriceplus
    baseprice-discount
    charges-discount

    exco-more - next element
    ===trigger-class:

    exco-row - next row in nugget-main
    ===trigger-class:

    ---------------------------------------------------------------

    TYPE OF NUGGET
    ===========================
    - account / company
    - user
    - panel

    - brand
    - product

    - order item / cart item
    - document / order / invoice
    - other objects

    - content / portals / sites
    - profiler groups
    - message
    - ticket

*/

$firstcolumn-width: 60px;
$firstcolumn-circle: calc(#{$firstcolumn-width} - 20px);
$nugget-aftercolumn: 35px;


// ==================================================================
// ROOTNUGGET
// ==================================================================

// .accountnugget,
// .locationnugget,
// .panelnugget,
// .usernugget,
// .brandnugget,
// .productnugget,
// .ordernugget,
// .documentnugget,
// .settingsnugget,
// .appnugget,
.rootnugget {
    height: auto;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #CCC;
    padding: 10px 0;

    &:hover,
    &.appactive {
        background-color: #d7e8f9;
    }

    &:hover>.nugget-card>.nugget-captions,
    &.appactive>.nugget-card>.nugget-captions,
    &:hover .dropdown-toggle,
    &.appactive .dropdown-toggle {
        color: #777;
    }

    .empty-nugget-part-25per {
        display: flex;
        justify-content: center;
        cursor: pointer;
        flex: 0 0 25%;
    }

    .nugget-main {
        flex: 1 1 auto; // Outside
        max-width: 100%;
        display: block; // Inside

        line-height: 1.4;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex-direction: column;

        * :not(.nugget-menu *) {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .nugget-settings * {
            text-overflow: ellipsis;
            overflow: visible;
            white-space: nowrap;
        }

        // rows inside main - each with two parts
        // >.any class or even without class,
        // >.nugget-card,
        // >.nugget-hierarchy,
        // >.nugget-toolbar,
        >* {
            display: grid;
            grid-template-columns: $firstcolumn-width 1fr;
            gap: 0;
            min-height: 24px;
            margin-bottom: 7px;

            >:first-child * :not(.nugget-menu *) {
                width: 100%;
                text-align: center;
            }

            >:nth-child(2) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .nugget-append {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;

        >.nugget-append-caption {
            padding: 0 30px 0 10px;
            height: 28px;
            border-radius: 0 20px 20px 0;
            background-color: #CCC;
            width: fit-content;
            display: flex;
            color: #555;

            &:hover {
                background-color: #506478;
                color: #FFF;
            }

            :first-child {
                flex: 0 0 20px;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            }

            // rotate the angle-arrow-down to opposite direction to hide
            &.expanded> :first-child::before {
                transform: rotate(90deg);
                transition: all .7s ease-in-out;
            }

            :nth-child(2) {
                flex: 0 0 auto;
                line-height: 28px;
                // border-top: 1px solid #555;
                // border-bottom: 1px solid #555;
                font-size: 12px;
                font-weight: normal;
            }

        }
    }

    // ==================================================================
    // TALL-NUGGET
    // ==================================================================

    &.tallnugget {
        >.nugget-main {

            // rows inside main - each with two parts
            >* {
                display: grid;
                grid-template-columns: 1fr;
                gap: 0;

                >:first-child * {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    // ==================================================================
    // NUGGET-AFTER + CLASSES FOR ICONS
    // ==================================================================

    .nugget-after {
        flex: 0 0 $nugget-aftercolumn;
        display: flex;
        justify-content: center;
        cursor: pointer;

        >.side-icon {
            margin: auto 0;
            cursor: pointer;
        }

        >.side-icon::after {
            color: #555;
            font-family: "Font Awesome 5 Free";
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
        }
    }

    &.verified {
        .nugget-green {
            // needed for verified icon after the child
            // for it to remain in the same row after ellipsis
            display: flex;
        }

        .nugget-green::after {
            color: #13d102;
            padding: 0 4px;
            font-family: "Font Awesome 5 Free";
            content: "\f058"; // shield-alt
            font-size: 14px;
            font-weight: 600;
        }
    }



    // ------------------------------------------------------------------
    // SIDE-ICON:AFTER
    // ------------------------------------------------------------------

    // sameframe-expanded
    &.sidearrow-up>.nugget-after {
        flex: 0 0 $nugget-aftercolumn;

        >.side-icon::after {
            content: "\f077"; //chevron-up
        }
    }

    // sameframe-collapsed
    &.sidearrow-down>.nugget-after {
        flex: 0 0 $nugget-aftercolumn;

        >.side-icon::after {
            flex: 0 0 $nugget-aftercolumn;
            content: "\f078"; //chevron-down
        }
    }

    // nextframe
    &.sidearrow-right>.nugget-after {
        flex: 0 0 $nugget-aftercolumn;

        >.side-icon::after {
            flex: 0 0 $nugget-aftercolumn;
            content: "\f105"; //chevron-right
        }
    }

    // menu
    &.threedots-horizontal>.nugget-after {
        flex: 0 0 $nugget-aftercolumn;

        >.side-icon::after {
            flex: 0 0 $nugget-aftercolumn;
            content: "\f141"; //ellipsis-h
        }
    }

    // menu
    &.threedots-vertical>.nugget-after {
        flex: 0 0 $nugget-aftercolumn;

        >.side-icon::after {
            flex: 0 0 $nugget-aftercolumn;
            content: "\f142"; //ellipsis-v
        }
    }
}

.nugget__rootclick-appmain {
    cursor: pointer;
}

.rootnugget.horizontal {
    .nugget-main {
        >* {
            grid-template-columns: 15% 1fr;
        }
    }
    .nugget-picture {
        padding-left : 0;
        padding-top : 0;
        padding-bottom : 0;
        width: 100%;
        height : 100%;

        > img {
            border-radius: 0;
            width: 100%;
            height : 100%;
        }
    }    
}

// ==================================================================
// NUGGET-ELEMENTS
// ==================================================================

// NUGGET-MEDIA || 3-ELEMENTS
// ------------------------------------------------------------------
.nugget-picture,
.nugget-icon,
.nugget-initials {
    // flex: 0 0 $firstcolumn-width;
    width: $firstcolumn-width;
    height: $firstcolumn-width;
    overflow: hidden;
}

.nugget-picture {
    padding: 0 10px 24px 10px;

    >img {
        border-radius: 50%;
        margin: 0 auto;
        height: $firstcolumn-circle;
        object-fit: contain;
        width: $firstcolumn-circle;
    }
}

.nugget-icon {
    padding: 10px;

    & .fa,
    & .fas,
    & .far {
        border-radius: 50%;
        border: 1px solid #CCC;
        margin: 0 auto;
        height: $firstcolumn-circle;
        width: $firstcolumn-circle;
        // Inside
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        color: #CCC;
    }
}

.nugget-initials {
    padding: 10px;

    & .initials {
        border-radius: 50%;
        border: 1px solid #555;
        margin: 0 auto;
        height: $firstcolumn-circle;
        width: $firstcolumn-circle;
        // Inside
        display: block;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: calc(#{$firstcolumn-circle} - 4px);

        background-color: #78C8D7;
        color: #555;
    }
}

// NUGGET-UTILITIES
// ------------------------------------------------------------------

// Usage: Any container where multiple elements
// need to be clubbed and  placed inline
.nugget-flex {
    display: flex;
    flex-wrap: wrap;
    min-height: 24px;
    align-items: center;
    gap: 10px;

    >* {
        flex: 0 0 max-content;
        padding: 0 5px;
        line-height: 24px;
    }
}

// Use anywhere in nugget to click directly on text
.nugget-textclicks {
    cursor: pointer;
    font-size: 12px;
    color: #008CC8;

    &:hover {
        color: #E15500;
    }
}

// All elements are set to show ellipsis
// Where not needed add this class
.letitwrap {
    white-space: normal !important;
}

// NUGGET-TEXT-ELEMENTS :: SPECIFIC
// ------------------------------------------------------------------

.nugget-title {
    color: rgba($color: #000, $alpha: 0.75);
    font-weight: 600;
    display: flow-root; // needed for ellipsis
}

.nugget-subtitle {
    display: flow-root; // needed for ellipsis
    color: rgba($color: #000, $alpha: 0.7);
}

.nugget-headline {
    color: rgba($color: #000, $alpha: 0.7);
    white-space: normal !important;
}

.nugget-pretitle {
    display: flow-root; // needed for ellipsis
    color: rgba($color: #000, $alpha: 0.5);
    font-size: 80%;
    text-transform: uppercase;
}

.nugget-type {
    color: rgba($color: #000, $alpha: 0.5);
    font-size: 80%;
    text-transform: uppercase;
}


// NUGGET-REFERENCES
// ------------------------------------------------------------------
// use span to combine these in one line as needed
// <div><span class="nugget-account">Account Name</span>-<span class="nugget-3cc">3CC</span></div>

.nugget-pin,
.nugget-brand,
.nugget-account,
.nugget-panel {
    color: #1E64B4;
}

.nugget-3cc,
.nugget-3CC {
    color: #E15500;
    font-weight: 600;
    text-transform: capitalize;
}

.nugget-sku,
.nugget-number {
    color: rgba($color: #000, $alpha: 0.8);
    font-size: 85%;
}

.nugget-date {
    color: rgba($color: #000, $alpha: 0.8);
    font-size: 85%;
}

// NUGGET-AMOUNT
// ------------------------------------------------------------------
.nugget-amount {
    // padding: 7px 0;

    .nugget-amount-whole {
        color: rgba($color: #000, $alpha: 0.9);
        font-weight: 600;
        font-size: 130%;
    }

    .nugget-amount-currency,
    .nugget-amount-fraction {
        color: rgba($color: #000, $alpha: 0.8);
        font-weight: 600;
        font-size: 80%;
        top: -0.5em;
        position: relative;
    }

    .nugget-amount-brackets {
        color: rgba($color: #000, $alpha: 0.7);
        font-size: 85%;
    }
}

.nugget-qty {
    // padding: 7px 0;
    margin: 5px 0;
    width: max-content;
    background-color: #f2f2f2;
    padding: 5px 12px;
    border-radius: 7px;

    .nugget-qty-caption {
        color: rgba($color: #000, $alpha: 0.8);
        font-size: 80%;
    }

    .nugget-qty-value {
        color: rgba($color: #000, $alpha: 0.8);
        // color: #2D5A91;
    }
}

.nugget-discount {
    // padding: 7px 0;

    .nugget-discount-caption {
        color: rgba($color: #000, $alpha: 0.8);
        font-size: 80%;
    }

    .nugget-discount-value {
        color: #509678;
        font-weight: bold;
    }

    .nugget-discount-savings {
        color: rgba($color: #000, $alpha: 0.7);
        font-size: 85%;
    }
}

// NUGGET-COMP
// ------------------------------------------------------------------
// comp = comp-header + comp-line + comp-subline + comp-subtotal + comp-total
.nugget-comp {
    padding: 10px;
    max-width: 400px;

    >* {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 16px;
        font-size: 80%;
        color: rgba($color: #000, $alpha: 0.7);

        >:nth-child(2) {
            text-align: right;
        }
    }

    .nugget-comp-header {
        border-bottom: 1px solid #000;
        color: rgba($color: #000, $alpha: 0.5);
    }

    .nugget-comp-line {
        color: rgba($color: #000, $alpha: 0.8);
    }

    .nugget-comp-subline>:first-child {
        padding-left: 12px;
    }


    .nugget-comp-subtotal {
        color: rgba($color: #000, $alpha: 0.8);
        font-weight: 600;
        border-top: 1px solid #555;
    }

    .nugget-comp-total {
        font-weight: 600;
        border-top: 1px solid #000;
        color: #000;
    }
}


// .nugget-toolbar>.nugget-menu
// ------------------------------------------------------------------
.nugget-menu {
    cursor: pointer;
    height: 24px;

    >.nugget-menuclick {
        border: none;
        box-shadow: none;
        background: transparent;
        color: #999;
        font-size: 16px;
        text-align: center;
        outline: 0;
        height: 100%;
        width: 100%;
        border-radius: 10%;

        * {
            line-height: 24px;
        }

        &:hover {
            background-color: #CCC;
            color: #000;
        }

        &.appdropdownshow {
            background-color: #CCC;
            color: #000;
        }
    }

    >.nugget-menuitems {
        &.appdropdownshow {
            color: #777;
            box-shadow: none;
            outline: 0;
        }

        &.appdropdownhide {
            display: none;
        }

        &.appdropdownshow {
            display: block;
        }
    }
}

// .nugget-toolbar
// ------------------------------------------------------------------
.nugget-ribbon {
    display: flex;
    flex-wrap: wrap;
    height: 24px;

    >* {
        flex: 0 0 max-content;
        padding: 0 5px;
        cursor: pointer;

        font-size: 12px;
        line-height: 24px;
        color: #777;
        // text-transform: uppercase;
        text-align: center;
        border-radius: 10%;

        &:hover {
            background-color: #CCC;
            color: #000;
        }
    }
}

// ==================================================================
// HIERARCHY -- TREELEVEL
// ==================================================================

.nugget-treelevel-number {
    font-size: 12px;
    line-height: 30px;
    color: #555;
    text-align: center;
    width: 100%;
}

.nugget-treelevel-marker {
    // Inside
    display: flex;
    height: 14px;
    margin: 8px 0;
    align-items: center;
    max-width: 200px;

    >.treelevel-marker-arrows {
        flex: 0 0 calc(100% - 36px);

        >.treelevel-marker1,
        >.treelevel-marker2 {
            display: block;
            height: 7px;
        }
    }

    &::after {
        background-color: #D2D3D4;
        content: ">10";
        // display: inline-flex;
        flex: 0 0 max-content;
        padding: 0 4px;
        height: 16px;
        margin-left: 3px;
        border-radius: 15%;
        font-size: 12px;
        line-height: 16px;
        color: #FFF;
        text-align: center;
    }
}

.treelevel-over10 {
    .nugget-treelevel-marker::after {
        background-color: #D99694;
    }
}

// ==================================================================
// TREELEVEL-MARKERS
// ==================================================================

.treelevel-1 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #D2D3D4 12.27% 18.87%,
                transparent 18.87% 21.70%, #D2D3D4 21.70% 28.30%,
                transparent 28.30% 31.13%, #D2D3D4 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #D2D3D4 12.27% 18.87%,
                transparent 18.87% 21.70%, #D2D3D4 21.70% 28.30%,
                transparent 28.30% 31.13%, #D2D3D4 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-2 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D2D3D4 21.70% 28.30%,
                transparent 28.30% 31.13%, #D2D3D4 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D2D3D4 21.70% 28.30%,
                transparent 28.30% 31.13%, #D2D3D4 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-3 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #D2D3D4 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #D2D3D4 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-4 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D2D3D4 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-5 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #D2D3D4 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-6 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D2D3D4 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-7 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #D2D3D4 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-8 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #78C8D7 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #78C8D7 68.87% 75.47%,
                transparent 75.47% 78.30%, #D2D3D4 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-9 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #78C8D7 68.87% 75.47%,
                transparent 75.47% 78.30%, #D99694 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #78C8D7 68.87% 75.47%,
                transparent 75.47% 78.30%, #D99694 78.30% 84.90%,
                transparent 84.90% 87.73%, #D2D3D4 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

.treelevel-10,
.treelevel-over10 {
    & .treelevel-marker1 {
        background-image: repeating-linear-gradient(45deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #78C8D7 68.87% 75.47%,
                transparent 75.47% 78.30%, #D99694 78.30% 84.90%,
                transparent 84.90% 87.73%, #78C8D7 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }

    & .treelevel-marker2 {
        background-image: repeating-linear-gradient(135deg,
                transparent 0.00% 2.83%, #D99694 2.83% 9.43%,
                transparent 9.43% 12.27%, #78C8D7 12.27% 18.87%,
                transparent 18.87% 21.70%, #D99694 21.70% 28.30%,
                transparent 28.30% 31.13%, #78C8D7 31.13% 37.73%,
                transparent 37.73% 40.57%, #D99694 40.57% 47.17%,
                transparent 47.17% 50.00%, #78C8D7 50.00% 56.60%,
                transparent 56.60% 59.43%, #D99694 59.43% 66.03%,
                transparent 66.03% 68.87%, #78C8D7 68.87% 75.47%,
                transparent 75.47% 78.30%, #D99694 78.30% 84.90%,
                transparent 84.90% 87.73%, #78C8D7 87.73% 94.33%,
                transparent 94.33% 100.00%);
    }
}

// ==================================================================
// ROOTNUGGET -- VARIATIONS
// ==================================================================

.rootnugget.settingsnugget {
    .nugget-title {
        font-size: 115%;
        font-weight: normal;
        color: rgba($color: #000, $alpha: 0.9);
    }
}
