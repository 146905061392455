// ==================================================================
// APPVERTABS
// ==================================================================

/***** Slide Left *****/
.full-height {
    height: 100%;
    
    .appvertabs {
        height: 100%;
    }

    .appvertabs-names {
        height: 100% !important;
    }
}

.appvertabs {
    display: flex;
    width: 100%;
    gap: 16px;
    position: relative;
    overflow-x: hidden;

    .appvertabs-names {
        position: relative;
        width: 14rem;
        height: auto;
        background-color: #f2f3f4;
        padding: 10px;
        flex: 0 0 200px;

        @media screen and (max-width:600px) {
            width: 100%;
            flex: 0 0 100%;
        }

        >* {
            // height: 2.5rem;
            padding: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
        }

        .appvertabs-slidingbox {
            position: absolute;
            opacity: 0;
            width: calc(100% - 20px);
            background-color: white;
            transition-property: transform;
            transition-duration: 200ms;
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
            height: 2.5rem;
            border-radius: 0.5rem;
            border: 0 solid #e2e8f0;
        }

        .apptab-active-item {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
            border-radius: 0.5rem;
            border: 0 solid #e2e8f0;
            background-color: white;
        }
    }

    .appvertabs-frame {
        flex: 1 1 auto;
        background-color: #fff;
        position: relative;
        width: 100%;
        height: auto;
        padding-right: 20px;

        overflow-x: hidden;
        scrollbar-color: var(--scrollbar-thumb-foreground) var(--scrollbar-thumb-background);
        scrollbar-width: thin;
        word-wrap: break-word;
        transition : all 750ms linear 0s;

        @media screen and (max-width:600px) {
            top: 0;
            height: 100%; 
        }

        &.appvertabs-active {
            display: block;
        }

        .appvertabs-frame-header {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #FFF;
            height: 36px;
            display: flex;
            gap: 5px;
            border-bottom: 1px solid #555;
            margin-bottom: 10px;
            
            .appvertabs-backbutton {
                width: 36px;
                flex: 0 0 36px;
                line-height: 35px;
                text-align: center;
                cursor: pointer;

                &:hover,
                >*:hover {
                    background-color: rgb(210, 210, 210);
                    color: #000;
                }

            }

            .appvertabs-title {
                flex: 1 1 auto;
                line-height: 35px;
                font-weight: 600;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: flow-root;
            }
        }
        .appvertabs-frame-body {
            height: calc(100% - 36px - 10px);
        }
        .appvertabs-current {
            padding: 10px;
        }
    }
}
