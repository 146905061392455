.form-text-value input,
.form-text-value fieldset,
.form-text-value .MuiInputAdornment-root {
    display: none;
}

.form-text-value .field-text {
    margin-top: 10px;
}


.form-text-value .field-text.editable {
    cursor : pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}