.section legend {
    cursor : pointer;
    color : #936;
    font-weight : 600;
}

.subsection > legend > span{
    cursor : pointer;
    color : #936;
    opacity: 0.7;
    font-weight : 600;
}

.sliding-box {
    position: relative;
    left: 100%;
    transition : all 1s linear 0s
}

.slide-left {
    transform: translate3d(-100%, 0px, 0px) scale3d(1, 1, 1);
}

.slide-right {
    transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1);
}

.box-legend {
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
} 

.box-legend-right {
    margin-left:"5px";
}