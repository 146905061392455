$applayout: 100vw;
$applayout-max: 100vw;

$large-breakpoint: 1600px;
$laptop-breakpoint: 1366px;
$tablet-breakpoint: 920px;
$mobile-breakpoint: 670px;

$appbar: 250px;
$appnav: 250px;
$appmenu: 250px;
$applist: 350px;
$appmain: auto;
$appfeed: 320px;


$usernavpic: 65px;
$panelnavbox: 600px;
$usernavbox: 600px;
$usernavheaderheight: 90px;
$usernavfooterheight: 28px;
$navconsole: 600px;

$mainheaderheight: 40px;
$tabBarheight: 35px;
$subheaderheight: 36px;
$subheaderlineheight: 35px;
$appnavitemheight: 32px;
$breadCrumbHeight: 36px;
$muiTabHeight : 35px;

$fonts: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

:export {
    largeBreakpoint: $large-breakpoint;
    laptopBreakpoint : $laptop-breakpoint;
    tabletBreakpoint : $tablet-breakpoint;
    mobileBreakpoint : $mobile-breakpoint;
    muiTabHeight : $muiTabHeight;
}